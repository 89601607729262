import { useState } from "react";
import { Button, Menu, MenuItem, Checkbox, ListItemText, List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
	roleData: any;
	selectedRoles: string[];
	onSave: (roles: string[]) => void;
}

const RoleDropdown = (props: Props) => {
	const [t] = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedRoles, setSelectedRoles] = useState<string[]>(props.selectedRoles.map((role: any) => role.id));

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setSelectedRoles(props.selectedRoles.map((role: any) => role.id));
		setAnchorEl(event.currentTarget);
	};

	const handleSave = () => {
		props.onSave(props.roleData.filter((role: any) => selectedRoles.includes(role.id)));
		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleToggle = (roleId: string) => () => {
		const currentIndex = selectedRoles.indexOf(roleId);
		const newSelectedRoles = [...selectedRoles];

		if (currentIndex === -1) {
			newSelectedRoles.push(roleId);
		} else {
			newSelectedRoles.splice(currentIndex, 1);
		}

		setSelectedRoles(newSelectedRoles);
	};

	const open = Boolean(anchorEl);

	return (
		<div style={{ marginRight: "16px" }}>
			<Button
				color="success"
				variant="text"
				onClick={handleClick}
				endIcon={open ? <span className="material-icons">keyboard_arrow_down</span> : <span className="material-icons">keyboard_arrow_up</span>}
				style={{ color: "#333", textTransform: "none" }}
			>
				{t("userSystem.roleName")}
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<div className="p-2">
					<List>
						{props.roleData.map((role: any) => (
							<ListItem
								key={role.id}
								dense
								button
								onClick={handleToggle(role.id)}
							>
								<Checkbox
									size="small"
									edge="start"
									color="success"
									checked={selectedRoles.indexOf(role.id) !== -1}
									disableRipple
								/>
								<ListItemText primary={role.roleName} />
							</ListItem>
						))}
					</List>
					<div style={{ marginTop: "8px", display: "flex", justifyContent: "space-between" }}>
						<Button
							color="success"
							disabled={selectedRoles.length === 0}
							onClick={() => setSelectedRoles([])}
						>
							{t("analyseModal.buttons.reset")}
						</Button>
						<div>
							<Button
								color="success"
								onClick={handleClose}
							>
								{t("analyseModal.buttons.close")}
							</Button>
							<Button
								variant="contained"
								color="success"
								onClick={handleSave}
							>
								{t("locations.add.save")}
							</Button>
						</div>
					</div>
				</div>
			</Menu>
		</div>
	);
};

export default RoleDropdown;
