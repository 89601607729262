import { Workbook } from "exceljs";

export const excelSheetLocation = (context: any, t:any, workbook: Workbook) => {
	const worksheet1 = workbook.getWorksheet(1);
	if (!worksheet1) {
		return;
	}

	const cellB2 = worksheet1.getCell("B2");
	cellB2.value = context.currentMultipleLocations?.name;

	const cellB3 = worksheet1.getCell("B3");
	cellB3.value = new Date().toLocaleDateString();
};
