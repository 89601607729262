export const excelLocation = (context: any, worksheet: any): any => {
	if (!worksheet) {
		return;
	}

	const locationObj = context.currentMultipleLocations;

	if (!locationObj) {
		return;
	}

	if (!locationObj.items || locationObj.items.length === 0) {
		return;
	}

	const checkedItems = locationObj.items.filter((item: any) => item.checked);

	if (checkedItems.length === 0) {
		return;
	}
    
    const dataItem = checkedItems.sort((a: any, b: any) => {
		if (!isNaN(a.code) && !isNaN(b.code)) {
			return a.code - b.code;
		}
		if (a.code < b.code) {
			return -1;
		}
		if (a.code > b.code) {
			return 1;
		}
		return 0;
	});

	// const data = dataItem.map((e: any) => [e.code, e.name, e.zipCode, e.street, e.number, e.county, e.place, e.province, e.country, e.countryISO3, e.latitude, e.longitude, e.coordinateSystem]);
	const data = dataItem.map((e: any) => [
		e.code,
		e.name,
		// (e.code || "").toString().replace("undefined", ""),
		// (e.name || "").toString().replace("undefined", ""),
		(e.zipCode || "").toString().replace("undefined", ""),
		(e.street || "").toString().replace("undefined", ""),
		(e.number || "").toString().replace("undefined", ""),
		(e.county || "").toString().replace("undefined", ""),
		(e.place || "").toString().replace("undefined", ""),
		(e.province || "").toString().replace("undefined", ""),
		(e.country || "").toString().replace("undefined", ""),
		(e.countryISO3 || "").toString().replace("undefined", ""),
		e.latitude,
		e.longitude,
		// (e.latitude || "").toString().replace("undefined", ""),
		// (e.longitude || "").toString().replace("undefined", ""),
		(e.coordinateSystem || "").toString().replace("undefined", ""),
	]);

	worksheet.addRows(data);

	return dataItem;
};
