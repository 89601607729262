import { AppAction, AppContext } from "helpers/context";
import { MapContext } from "helpers/map";
import { useContext, useEffect, useState } from "react";
import { LocationData } from "helpers/models";
import { LeftPanel, RightPanel, zoomLevelMedium } from "helpers/constants";

interface Props {
    position: number[] | string[];
    imageUrl?: string;
    anchor?: string;
    label?: string;
    isLocation?: boolean;
    locationData?: LocationData;
}

const WorldZoom: React.FC<Props> = ({ position, imageUrl = "", anchor = "center", label, isLocation, locationData }) => {

    const [context, dispatch] = useContext(AppContext);
    const { AMap, map } = useContext(MapContext);
    const handleMarkerClick = (e: any) => {
        if (context.currentMultipleLocationInfo && locationData && (context.currentMultipleLocationInfo.id === locationData.id)) {
            dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
            dispatch({ type: AppAction.setCurrentMultipleLocationInfo, payload: undefined });
        } else {
            dispatch({ type: AppAction.setSearchMapCenter, payload: undefined });
            dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
            dispatch({ type: AppAction.setCurrentMultipleLocationInfo, payload: locationData });
            dispatch({ type: AppAction.setMapZoom, payload: zoomLevelMedium });
            dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Single });
        }
    }

    useEffect(() => {
        if (!position) return
        // 在这里编写组件挂载时的逻辑
        let icon
        if (imageUrl) {
            icon = new AMap.Icon({
                // 图标尺寸
                size: context.currentMultipleLocationInfo && locationData && (context.currentMultipleLocationInfo.id === locationData.id) ? new AMap.Size(69, 42) : new AMap.Size(46, 28),
                // 图标的取图地址
                image: imageUrl,
                // 图标所用图片大小
                imageSize: context.currentMultipleLocationInfo && locationData && (context.currentMultipleLocationInfo.id === locationData.id) ? new AMap.Size(69, 42) : new AMap.Size(46, 28)
            });
        }
        // 将 Icon 传入 marker
        let marker = new AMap.Marker({
            position: position,
            icon: icon,
            anchor: anchor, //设置锚点
            // label: label
        });
        let textMarker: any = null;
        if (label) {
            textMarker = new AMap.Text({
                text: label, //标记显示的文本内容
                anchor: "center", //设置文本标记锚点位置
                draggable: false, //是否可拖拽
                style: {
                    "z-index": "2",
                    "border": "1px solid #00f",
                    "background-color": "#fff",
                    "white-space": "nowrap",
                    "cursor": "default",
                    "padding": "3px",
                    "line-height": "14px",
                    "border-radius": "0",
                    "height": "22px",
                    "color": "#666"
                },
                offset: new AMap.Pixel(0, -27),
                position: position, //点标记在地图上显示的位置
                map: map
            });
        }
        map.add(marker)
        // isLocation && map.setZoomAndCenter(context.mapZoom, position);
        isLocation && map.setCenter(position);
        if (locationData) {
            marker.on('click', handleMarkerClick);
        }
        return () => {
            // 在这里编写组件卸载时的逻辑
            if (locationData) {
                marker.off('click', handleMarkerClick);
            }
            if (marker) {
                map.remove(marker);
                marker = null;
            }
            if (textMarker) {
                map.remove(textMarker);
                marker = null;
            }
        };
    }, [position, imageUrl, anchor, label]);
    return (
        <div></div>
    );
}

export default WorldZoom;