import { ReactComponent as LocationsPinSvg } from "assets/pin_locations.svg";
import Button from "@mui/material/Button";
import Icon from "components/icon/icon";
import Results from "components/results/results";
import Tooltip from "@mui/material/Tooltip";
import ExcelJS, { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { getCheckedCounts } from "helpers/helpers";
import { getLanguage } from "helpers/translations";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { config } from "./config";
import { climateUtil, resetRowNum } from "./excel-climate";
import { tx } from "./excel-util";
import styles from "./multi-locations.module.scss";
import { downloadExcel } from "./download/excel-download";

function MultiLocations() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const openModal = (): void => {
		dispatch({ type: AppAction.setAnalyseModal, payload: "2" });
	};

	const closeLocations = (): void => {
		dispatch({ type: AppAction.setJobId, payload: undefined });
		dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Empty });
		// dispatch({ type: AppAction.setLeftPanel, payload: LeftPanel.Empty });
		dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
		dispatch({ type: AppAction.setCurrentMultipleLocations, payload: undefined });
		dispatch({ type: AppAction.setMapCenter, payload: undefined });
		dispatch({ type: AppAction.setSingleLocation, payload: undefined });
		dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
	};

	function getDate() {
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const date = today.getDate();
		return `${date}/${month}/${year}`;
	}

	const download = async () => {
		downloadExcel(context, t);
	};

	const downloadAnalysis = async () => {
		const language = getLanguage();
		const templateUrl = language === "en" ? "/template-en.xlsx" : "/template-new.xlsx";
		const response = await fetch(templateUrl);
		const templateBlob = await response.blob();
		const templateArray = await new Promise<Uint8Array>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(new Uint8Array(reader.result as ArrayBuffer));
			reader.onerror = reject;
			reader.readAsArrayBuffer(templateBlob);
		});
		const workbook: Workbook = new ExcelJS.Workbook();

		await workbook.xlsx.load(templateArray);

		const worksheet1 = workbook.getWorksheet(1);
		if (!worksheet1) {
			return;
		}

		const locationObj = context.currentMultipleLocations;
		console.log(locationObj);

		const locationSetName = locationObj?.name;
		const cellB2 = worksheet1.getCell("B2");
		cellB2.value = locationSetName;

		// const createTime = locationObj?.createTime;
		// const cellB3 = worksheet1.getCell("B3");
		// cellB3.value = createTime;

		const cellB3 = worksheet1.getCell("B3");
		cellB3.value = getDate();

		if (!locationObj) {
			return;
		}

		if (!locationObj.items || locationObj.items.length === 0) {
			return;
		}

		console.log(locationObj.items);

		const data = locationObj.items.map((e) => [
			e.code,
			e.name,
			e.zipCode,
			e.street,
			e.number,
			e.county,
			e.place,
			e.province,
			e.country,
			e.countryISO3,
			e.latitude,
			e.longitude,
			e.coordinateSystem,
		]);

		console.log(data);

		data.forEach((item, index) => {
			item.forEach((value, i) => {
				if (!value) {
					data[index][i] = "";
					return;
				}
				if (value === undefined) {
					data[index][i] = "";
					return;
				}
				// if (value.toString().includes("NaN")) {
				// 	data[index][i] = value.replace("NaN", "");
				// 	return;
				// }
				// if (value.toString().includes("null")) {
				// 	data[index][i] = value.replace("null", "");
				// 	return;
				// }
				if (value.toString().includes("undefined")) {
					data[index][i] = value.replace("undefined", "");
					return;
				}
			});
		});

		console.log(data);

		const hazardResult = context.hazardMultipleAnalysisResult;
		console.log("hazardResult", hazardResult);

		// const hazardLegend = context.hazardMultipleAnalysedLegends;
		// console.log(hazardLegend);

		const climateResult = context.climateMultipleAnalysisResult;
		console.log("climateResult", climateResult);

		for (let i = 2; i < 6; i++) {
			const worksheet = workbook.getWorksheet(i);

			if (!worksheet) {
				continue;
			}

			worksheet.addRows(data);

			if (i === 2) {
				// const obj: any = {};

				hazardResult?.forEach((e) => {
					if (!e) {
						debugger;
						return;
					}

					const col = config[e.layerId];
					const value = e.valueLabel;
					tx(col, value, worksheet, OBJ, t);

					if (!e.details) {
						return;
					}

					if (typeof e !== "object") {
						return;
					}

					const keysInDetails = Object.keys(e.details);

					if (!keysInDetails || keysInDetails.length === 0) {
						const keysInConfig = Object.keys(config);
						if (!keysInConfig || keysInConfig.length === 0) {
							return;
						}

						const keysInFilter = keysInConfig.filter((key) => key !== e.layerId && key.includes(e.layerId) && !key.includes("intensity"));
						keysInFilter.forEach((key) => {
							const c = config[key];
							const v = t("legend.No_Data");
							tx(c, v, worksheet, OBJ, t);
						});
					} else {
						keysInDetails.forEach((key) => {
							const details = e.details as any;
							if (details[key] === null) {
								return;
							}

							const c = config[e.layerId + "." + key];

							if (!c) {
								return;
							}

							const v = details[key];
							tx(c, v, worksheet, OBJ, t);
						});
					}

					const c = config[e.layerId + ".intensity"];
					const v = e.intensity;

					if (!c) {
						return;
					}

					tx(c, v, worksheet, OBJ, t);

					if (!e.hailAdditionalDetails) {
						return;
					}

					if (typeof e.hailAdditionalDetails !== "object") {
						return;
					}

					const hailAdditionalDetails = e.hailAdditionalDetails as any;
					const set31 = hailAdditionalDetails["HailHazard-31-year-historical-data-set"];
					const set64 = hailAdditionalDetails["HailHazard-64-year-historical-data-set"];

					if (set31) {
						Object.keys(set31).forEach((key) => {
							const c = config[e.layerId + ".31-set." + key];
							const v = set31[key];
							tx(c, v, worksheet, OBJ, t);
						});
					}

					if (set64) {
						Object.keys(set64).forEach((key) => {
							const c = config[e.layerId + ".64-set." + key];
							const v = set64[key];
							tx(c, v, worksheet, OBJ, t);
						});
					}
				});

				continue;
			}

			if (!climateResult || climateResult.length === 0) {
				continue;
			}

			climateResult.forEach((e) => {
				if (!e) {
					return;
				}
				console.log("===========");
				console.log(e);

				const climateRiskScores = e.climateRiskScores;
				if (!climateRiskScores || climateRiskScores.length === 0) {
					return;
				}
				climateRiskScores.forEach((score) => {
					const index = score.index;
					const scenarios = score.scenarios;

					console.log(index);
					if (!index || !scenarios || scenarios.length === 0) {
						return;
					}
					scenarios.forEach((scenario) => {
						const name = scenario.name;
						console.log(name);

						const bandGroups = scenario.bandGroups;
						if (!name || !bandGroups || bandGroups.length === 0) {
							return;
						}

						if (i === 3 && name === "SSP1-2.6") {
							climateUtil(i, index, bandGroups, worksheet, t);
							return;
						}

						if (i === 4 && name === "SSP2-4.5") {
							climateUtil(i, index, bandGroups, worksheet, t);
							return;
						}

						if (i === 5 && name === "SSP5-8.5") {
							climateUtil(i, index, bandGroups, worksheet, t);
							return;
						}
					});
				});
			});
		}

		resetRowNum();

		const buf = await workbook.xlsx.writeBuffer();

		saveAs(new Blob([buf], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), `${locationSetName}.xlsx`);
	};

	const addLocation = () => {
		setLeftPanel(dispatch, LeftPanel.AddLocation);
	};

	const OBJ: any = {};

	return (
		<div className={styles.multiple}>
			<div>
				<div className={styles.title}>
					<div>{context.currentMultipleLocations?.name}</div>
					<div className="flex items-center">
						{/* <div
							className="tooltip z-50"
							data-tip={t("single.addLocation")}
						>
							<div className={styles.icon}>
								<AddLocationSvg onClick={addLocation} />
							</div>
						</div> */}
						<div>
							<Icon onClick={closeLocations} />
						</div>
					</div>
				</div>
				<div className="flex flex-row justify-items-start p-[16px]">
					<div className={styles.image}>
						<LocationsPinSvg />
					</div>
					<div className={styles.address}>
						<div>
							<div style={{ display: "flex" }}>
								<div style={{ marginRight: "5px" }}>{t("multiple.aggregationBy")}</div>
								<div>{t("multiple.NumberOfLocations")}</div>
							</div>
							<div>
								{t("multiple.createTime")}: {context.currentMultipleLocations?.createTime}
							</div>
							{context.multipleLocationsAnalysed && (
								<div>
									{t("multiple.analysisDate")}: {getDate()}
								</div>
							)}
							{/* <Button>{t("multiple.details")}</Button> */}
						</div>
					</div>
				</div>
				{context.multipleLocationsAnalysed ? (
					<Results />
				) : (
					<div className={styles.locationCount}>
						{getCheckedCounts(context.currentMultipleLocations?.items)} {t("multiple.locations")}
					</div>
				)}
			</div>
			<div className={styles.buttons}>
				{context.multipleLocationsAnalysed && (
					<div className="w-fit mr-3">
						{/* <a
							href="/CatNet_Report_Multiple_Premium.pdf"
							target="_blank"
							rel="noreferrer"
						>
							<Button type="outlined">{t("multiple.buttonDownload")}</Button>
						</a> */}
						<Button
							color="success"
							variant="outlined"
							onClick={download}
						>
							{t("multiple.buttonDownload")}
						</Button>
					</div>
				)}
				<Tooltip
					title={t("multiple.noLocationsSelected")}
					placement="top"
					disableHoverListener={!(getCheckedCounts(context.currentMultipleLocations?.items) === "0")}
				>
					<div className="flex-1">
						<Button
							onClick={openModal}
							disabled={getCheckedCounts(context.currentMultipleLocations?.items) === "0"}
							variant="contained"
							color="success"
							style={{ width: "100%" }}
						>
							{t("multiple.buttonAnalyseSelected")}
						</Button>
					</div>
				</Tooltip>
			</div>
		</div>
	);
}

export default MultiLocations;
