import Button from "@mui/material/Button";
import { removeTenant } from "api/layer";
import Modal from "components/modal/modal";
import { AppAction, AppContext } from "helpers/context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./delete-tenant-modal.module.scss";

interface Props {
	data: any;
	initData: () => void;
	onClose: () => void;
}

const DeleteTenantModal: React.FC<Props> = ({ data, initData, onClose }) => {
	const [t] = useTranslation();
	const [context, dispatch] = useContext(AppContext);

	const confirmDeleteTenant = (): void => {
		removeTenant(data.id)
			.then((resp: any) => {
				if (!resp || resp.code !== 200) {
					dispatch({
						type: AppAction.setSnackBarParams,
						payload: {
							title: "deleteTenant",
							titleIconColor: "yellow",
							titleIcon: "warning",
							resMessage: resp.message,
						},
					});
					return;
				}
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "deleteTenant",
						
						
						message: "deleteTenantSuccess",
					},
				});
			})
			.catch((error: any) => {
				console.error(error);
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "deleteTenant",
						titleIconColor: "red",
						titleIcon: "error",
						message: "deleteTenantError",
					},
				});
			})
			.finally(() => {
				initData();
				onClose();
			});
	};

	return (
		<Modal
			header={t("userSystem.deleteTenant.title")}
			opened={!!data.id}
			onClose={onClose}
		>
			<div className={styles.info}>
				<div className={styles.text}>{`${t("userSystem.deleteTenant.prefix")}${data.tenantName}${t("userSystem.deleteTenant.suffix")}`}</div>

				<div className={styles.button}>
					<Button
						variant="text"
						color="success"
						onClick={onClose}
						style={{ outline: "none" }}
					>
						{t("userSystem.deleteTenant.cancel")}
					</Button>
					&nbsp;&nbsp;
					<Button
						variant="contained"
						color="error"
						onClick={confirmDeleteTenant}
					>
						{t("userSystem.deleteTenant.delete")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default DeleteTenantModal;
