import { Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as turf from "@turf/turf";
import { addLocationSetItems, getLocationsetsItems, patchLocationsetsItems } from "api/layer";
import china from "assets/json/c1.json";
import countryArray from "assets/json/country.json";
import Icon from "components/icon/icon";
import Modal from "components/modal/modal";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./add-location.module.scss";

interface LocationData {
	locationSetId: string;
	code: string;
	name: string;
	country: string;
	county: string;
	longitude: string;
	latitude: string;
	number: string;
	place: string;
	province: string;
	street: string;
	zipCode: string;
	countryISO3: string;
	coordinateSource: string;
	coordinateSystem: string;
}

export default function AddLocation() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const options = context.multipleLocations || [];
	const locationSetId: string = context.currentMultipleLocations ? context.currentMultipleLocations.id : "";
	let LocationData = {
		locationSetId,
		code: "",
		name: "",
		country: "",
		county: "",
		longitude: "",
		latitude: "",
		number: "",
		place: "",
		province: "",
		street: "",
		zipCode: "",
		countryISO3: "",
		coordinateSource: "addLocation",
		coordinateSystem: "",
	};

	if (context.leftPanel === LeftPanel.AddLocation && context.searchLocationInfo) {
		const locationInfo = context.searchLocationInfo[context.language];

		const country = typeof locationInfo.country === "string" ? locationInfo.country : "";
		const county = typeof locationInfo.district === "string" ? locationInfo.district : "";
		const longitude = locationInfo.location.split(",")[0];
		const latitude = locationInfo.location.split(",")[1];
		const number = locationInfo.streetNumber && typeof locationInfo.streetNumber.number === "string" ? locationInfo.streetNumber.number : "";
		const place = typeof locationInfo.formatted_address === "string" ? locationInfo.formatted_address : "";
		const province = typeof locationInfo.province === "string" ? locationInfo.province : "";
		const street = locationInfo.streetNumber && typeof locationInfo.streetNumber.street === "string" ? locationInfo.streetNumber.street : "";
		const zipCode = typeof locationInfo.adcode === "string" ? locationInfo.adcode : "";
		let countryISO3 = "CN";
		if (country) {
			const countryItem = countryArray.filter((item) => item.label.toLowerCase().includes(country.toLowerCase()))[0];
			if (countryItem) {
				countryISO3 = countryItem.iso3;
			}
		}

		LocationData = {
			locationSetId,
			code: "",
			name: "",
			country,
			county,
			longitude,
			latitude,
			number,
			place,
			province,
			street,
			zipCode,
			countryISO3,
			coordinateSource: "addLocation",
			coordinateSystem: "",
		};
	} else {
		LocationData = { ...context.editMultipleLocation, locationSetId };
	}

	const [Location, setLocation] = useState<LocationData>(LocationData);
	const [disabled, setDisabled] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [isClose, setIsClose] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [Loading, setLoading] = useState(false);

	const save = async () => {
		setDisabled(true);
		if (!(Location.locationSetId && Location.code && Location.name && Location.longitude && Location.latitude)) {
			setDisabled(false);
			setIsError(true);
			return;
		}
		setIsError(false);
		setLoading(true);
		if (context.leftPanel === LeftPanel.AddLocation) {
			dispatch({ type: AppAction.setMultipleLocationsSetLoadings, payload: true });
			try {
				const longitude = parseFloat(Location.longitude);
				const latitude = parseFloat(Location.latitude);
				if (isNaN(longitude) || isNaN(latitude)) {
					setDisabled(false);
					setIsError(true);
					return;
				}

				const point = turf.point([longitude, latitude]);
				const chinaGeojson = turf.polygon(china.features[0].geometry.rings);
				const isChina = turf.booleanPointInPolygon(point, chinaGeojson);
				if (isChina) {
					Location.coordinateSystem = "GCJ02";
				} else {
					Location.coordinateSystem = "WGS84";
				}
				let res = await addLocationSetItems(Location.locationSetId, Location);
				let resItems: any = [];
				let multipleLocations = JSON.parse(JSON.stringify(context.multipleLocations));
				multipleLocations?.forEach(async (item: any) => {
					if (item.id === res.data.locationSetId) {
						if (item.items === null) {
							resItems = await getLocationsetsItems(Location.locationSetId);
							item.items = resItems.data.items.map((i: any) => {
								if (i.checked === undefined) {
									i.checked = false;
								}
								if (res.data.id === i.id) {
									i.checked = true;
								}
								return i;
							});
						} else {
							item.items!.push({ ...res.data, checked: true });
						}

						if (item.itemCount === null) {
							item.itemCount = resItems.data.itemCount;
						} else {
							item.itemCount += 1;
						}

						let currentMultipleLocations = JSON.parse(JSON.stringify(item));
						dispatch({ type: AppAction.setCurrentMultipleLocations, payload: currentMultipleLocations });
						dispatch({ type: AppAction.setMultipleLocationsSetLoadings, payload: false });
					}
				});
				if (context.currentMultipleLocations && context.currentMultipleLocations!.id !== res.data.locationSetId) {
					dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: false });
				}
				dispatch({ type: AppAction.setSingleLocation, payload: undefined });
				dispatch({ type: AppAction.setSingleLocationAnalysed, payload: undefined });
				dispatch({ type: AppAction.setMultipleLocationsEdit, payload: true })
				dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
				dispatch({ type: AppAction.setMultipleLocations, payload: multipleLocations });
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "createdLocationTitle",
						text: Location.name,
						message1: "createdLocationMessage1",
						message2: "createdLocationMessage2",
					},
				});

			} catch (error) {
				setDisabled(false);
			}
		} else {
			try {
				let res = await patchLocationsetsItems(context.currentMultipleLocations!.id, context.editMultipleLocation.id, Location);
				context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((item) => {
					if (item.id === context.editMultipleLocation.id) {
						item = { ...item, ...res.data };
					}
					return item;
				});
				let multipleLocations = context.multipleLocations?.map((item) => {
					if (item.id === context.currentMultipleLocations!.id) {
						item.items = context.currentMultipleLocations!.items;
					}
					return item;
				});

				dispatch({ type: AppAction.setCurrentMultipleLocations, payload: context.currentMultipleLocations });
				dispatch({ type: AppAction.setMultipleLocations, payload: multipleLocations });
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "editLocationTitle",
						text: context.editMultipleLocation.name,
						message1: "editLocationMessage1",
						message2: "editLocationMessage2",
					},
				});
				dispatch({ type: AppAction.setEditMultipleLocation, payload: null });
			} catch (error) {
				setDisabled(false);
			}
		}
		setDisabled(false);
		setLoading(false);
		setLeftPanel(dispatch, LeftPanel.Locations);
	};

	const handleChange = (event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = event.target;

		if (name === "locationSetId" && value === "") {
			return;
		}

		if (context.leftPanel === LeftPanel.EditLocation && context.editMultipleLocation[name] === value) {
			setIsChange(false);
		} else {
			setIsChange(true);
		}

		setLocation((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleAddLocationSet = (e: any) => {
		dispatch({ type: AppAction.setCreateModal, payload: true });
	};

	const cancel = () => {
		if (context.leftPanel === LeftPanel.EditLocation && isChange) {
			setIsClose(true);
		} else {
			close();
		}
	};

	const close = () => {
		setLeftPanel(dispatch, LeftPanel.Locations);
		dispatch({ type: AppAction.setEditMultipleLocation, payload: null });
	};

	const handleDelete = () => {
		dispatch({
			type: AppAction.setDeleteLocationModal,
			payload: context.editMultipleLocation,
		});
	};

	const handleClose = () => {
		setIsClose(false);
	};

	const handleChangeExpand = () => {
		setExpanded(expanded ? false : true);
	};

	useEffect(() => {
		if (context.currentMultipleLocations) {
			setLocation((prevData) => ({ ...prevData, locationSetId: context.currentMultipleLocations!.id }));
		}
	}, [context.currentMultipleLocations])

	return (
		<div className={styles.add}>
			<div className={styles.header}>
				<div className={styles.title}>
					<div className="flex items-center">
						<Icon
							icon="arrow_back"
							onClick={cancel}
						/>
						<span>{context.leftPanel === LeftPanel.EditLocation ? t("locations.add.editTitle") : t("locations.add.title")}</span>
					</div>
					<div>
						<Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
					</div>
				</div>
			</div>
			{Loading ? (
				<div className={styles.loading}>
					<div className="loading loading-spinner loading-lg"></div>
					<div>{t("layers.savingLocation")}</div>
				</div>
			) : (
				<>
					<div className={styles.body}>
						<Stack spacing={{ xy: 1, sm: 2 }}>
							<FormControl fullWidth>
								<InputLabel
									required
									color="success"
								>
									{t("locations.add.Location_set")}
								</InputLabel>
								<Select
									required
									name="locationSetId"
									value={Location.locationSetId}
									disabled={context.leftPanel === LeftPanel.EditLocation}
									onChange={handleChange}
									error={isError && !Location.locationSetId}
									label={t("locations.add.Location_set")}
									color="success"
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 350,
											},
										},
									}}
								>
									{options.map((option, index) => (
										<MenuItem
											key={index}
											value={option.id}
										>
											{option.name}({option.itemCount})
										</MenuItem>
									))}
									<MenuItem
										style={{ padding: 0, borderTop: "1px solid #0000001f", height: 45 }}
										value=""
									>
										<Button
											variant="text"
											color="success"
											style={{ width: "100%", height: "100%" }}
											onClick={handleAddLocationSet}
										>
											{t("single.addNewLocationSet")}
										</Button>
									</MenuItem>
								</Select>
							</FormControl>
							<TextField
								required
								name="code"
								value={Location.code}
								onChange={handleChange}
								error={isError && !Location.code}
								label={t("locations.add.code")}
								variant="outlined"
								color="success"
							/>
							<TextField
								required
								name="name"
								value={Location.name}
								onChange={handleChange}
								error={isError && !Location.name}
								label={t("locations.add.name")}
								variant="outlined"
								color="success"
							/>
							<Stack
								spacing={{ xs: 1 }}
								direction="row"
								useFlexGap
								sx={{ flexWrap: "wrap" }}
							>
								<TextField
									required
									sx={{ flex: 1 }}
									name="longitude"
									value={Location.longitude}
									onChange={handleChange}
									error={isError && !Location.longitude}
									label={t("locations.add.longitude")}
									variant="outlined"
									color="success"
								/>
								<TextField
									required
									sx={{ flex: 1 }}
									name="latitude"
									value={Location.latitude}
									onChange={handleChange}
									error={isError && !Location.latitude}
									label={t("locations.add.latitude")}
									variant="outlined"
									color="success"
								/>
							</Stack>
							<Accordion
								expanded={expanded}
								onChange={handleChangeExpand}
								sx={{
									boxShadow: "none",
									backgroundColor: "transparent",
									margin: "12px 0 0!important",
									"&::before": {
										display: "none",
									},
								}}
							>
								<AccordionSummary
									expandIcon={<div className="material-icons">keyboard_arrow_right</div>}
									sx={{
										padding: 0,
										margin: 0,
										flexDirection: "row-reverse",
										"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
											transform: "rotate(90deg)",
										},
										"& .MuiTypography-root": {
											fontFamily: "SwissReSansOT-Light",
										},
										"&.Mui-expanded": {
											minHeight: "48px",
										},
										"& .MuiAccordionSummary-content.Mui-expanded": {
											margin: "12px 0",
										},
									}}
								>
									{t("locations.add.address")}
								</AccordionSummary>
								<AccordionDetails
									sx={{
										padding: "6px 0 0",
										width: "100%",
									}}
								>
									<Stack spacing={{ xy: 1, sm: 2 }}>
										<TextField
											// required
											name="zipCode"
											value={Location.zipCode}
											onChange={handleChange}
											label={t("locations.add.zipCode")}
											variant="outlined"
											color="success"
										/>
										<Stack
											spacing={{ xs: 1 }}
											direction="row"
											useFlexGap
											sx={{ flexWrap: "wrap" }}
										>
											<TextField
												// required
												sx={{ flex: 1 }}
												name="street"
												value={Location.street}
												onChange={handleChange}
												label={t("locations.add.streetName")}
												variant="outlined"
												color="success"
											/>
											<TextField
												// required
												sx={{ flex: 1 }}
												name="number"
												value={Location.number}
												onChange={handleChange}
												label={t("locations.add.streetNumber")}
												variant="outlined"
												color="success"
											/>
										</Stack>
										<TextField
											// required
											name="place"
											value={Location.place}
											onChange={handleChange}
											label={t("locations.add.place")}
											variant="outlined"
											color="success"
										/>
										<TextField
											// required
											name="county"
											value={Location.county}
											onChange={handleChange}
											label={t("locations.add.county")}
											variant="outlined"
											color="success"
										/>
										<TextField
											// required
											name="province"
											value={Location.province}
											onChange={handleChange}
											label={t("locations.add.province")}
											variant="outlined"
											color="success"
										/>
										<TextField
											// required
											name="country"
											value={Location.country}
											onChange={handleChange}
											label={t("locations.add.country")}
											variant="outlined"
											color="success"
										/>
									</Stack>
								</AccordionDetails>
							</Accordion>
						</Stack>
					</div>
					<div className={styles.footer}>
						<div>
							<Button
								type="submit"
								variant="contained"
								disabled={disabled}
								onClick={save}
								color="success"
							>
								{t("locations.add.save")}
							</Button>
							{isChange && (
								<Button
									variant="text"
									onClick={cancel}
									color="success"
									style={{ marginLeft: "10px" }}
								>
									{t("locations.add.cancel")}
								</Button>
							)}
						</div>
						{context.leftPanel === LeftPanel.EditLocation && (
							<Button
								variant="text"
								onClick={handleDelete}
								color="error"
							>
								{t("locations.add.delete")}
							</Button>
						)}
					</div>
				</>
			)}

			<Modal
				header={t("locations.add.leaveTitle")}
				opened={isClose}
				onClose={handleClose}
			>
				<div className={styles.info}>
					<div className={styles.text}>{t("locations.add.leaveMessage")}</div>

					<div className={styles.button}>
						<Button
							variant="text"
							color="success"
							onClick={handleClose}
							style={{ marginRight: "10px" }}
						>
							{t("locations.add.cancel")}
						</Button>
						<Button
							variant="outlined"
							color="success"
							onClick={close}
							style={{ marginRight: "10px" }}
						>
							{t("locations.add.leave")}
						</Button>
						<Button
							variant="contained"
							type="submit"
							disabled={disabled}
							onClick={save}
							color="success"
						>
							{t("locations.add.save")}
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
