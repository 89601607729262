import Button from "@mui/material/Button";
import Modal from "components/modal/modal";
import { AppAction, AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./location-analysis-details.module.scss";
import { ReactComponent as IconChart } from "assets/icon_chart.svg";

export default function LocationAnalysisDetails() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const close = (): void => {
		dispatch({ type: AppAction.setShowLocationAnalysisDetails, payload: false });
	};

	return (
		<Modal
			header={t("locationAnalysisDetails.title")}
			opened={context.showLocationAnalysisDetails}
			onClose={close}
		>
			<div className={styles.modal}>
				<div className={styles.info}>
					<div className={styles.text}>{t("locationAnalysisDetails.text1")}</div>
					<div style={{ display: "flex", alignItems: "center" }} className={styles.text}>
						<IconChart />
						<div style={{ marginLeft: "5px" }}>A/B</div>
					</div>
					<div className={styles.text}>{t("locationAnalysisDetails.text2")}</div>
					<div className={styles.text}>{t("locationAnalysisDetails.text3")}</div>
				</div>
				<div className={styles.example}>
					<div className={styles.text}>{t("locationAnalysisDetails.example")}</div>
					<div style={{ display: "flex", alignItems: "center" }} className={styles.text}>
						<IconChart />
						<div style={{ marginLeft: "5px" }}>8/10</div>
					</div>
					<div className={styles.text}>{t("locationAnalysisDetails.info")}</div>
				</div>
			</div>
			<div className={styles.button} style={{ textAlign: "right", marginTop: "10px" }}>
				<Button
					color="success"
					onClick={close}
				>
					{t("locationAnalysisDetails.close")}
				</Button>
			</div>
		</Modal>
	);
}
