import { useState, useEffect, useContext } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Tabs,
  Tab,
  Toolbar,
  IconButton,
  TextField,
  Box,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { getAnalysisStatistics, downloadAnalysisStatistics } from "api/layer";
import { AppContext, AppAction } from "helpers/context";
import styles from "./analysisStatistics.module.scss";
import StartTimeDropdown from "./start-time-dropdown/start-time-dropdown";
import EndTimeDropdown from "./end-time-dropdown/end-time-dropdown";
import dayjs from "dayjs";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AnalysisStatistics() {
  const { t } = useTranslation();
  const [context, dispatch] = useContext(AppContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [analysisStatisticsData, setAnalysisStatisticsData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalStatistic, setTotalStatistic] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [searchIpt, setSearchIpt] = useState("");
  const [searchData, setSearchData] = useState<{
    keyword: string;
    startOccurTime: any;
    endOccurTime: any;
  }>({
    keyword: "",
    startOccurTime: null,
    endOccurTime: null,
  });

  const [sortByField, setSortByField] = useState<"start_date" | "end_date" | "tenant_name" | "user_account" | "layer_amount" | "single_lookup_count" | "batch_lookup_count" | "total_location_amount" | "total_lookup_amount">("batch_lookup_count");
  const [sortOrder, setSortOrder] = useState<{
    start_date: "asc" | "desc",
    end_date: "asc" | "desc",
    tenant_name: "asc" | "desc",
    user_account: "asc" | "desc",
    layer_amount: "asc" | "desc",
    single_lookup_count: "asc" | "desc",
    batch_lookup_count: "asc" | "desc",
    total_location_amount: "asc" | "desc",
    total_lookup_amount: "asc" | "desc",
  }>({
    start_date: "asc",
    end_date: "asc",
    tenant_name: "asc",
    user_account: "asc",
    layer_amount: "asc",
    single_lookup_count: "asc",
    batch_lookup_count: "desc",
    total_location_amount: "asc",
    total_lookup_amount: "asc",
  });

  const hanldeDownload = () => {
    downloadAnalysisStatistics({ ...searchData, language: context.language }).then((res: any) => {
      console.log(res);
      const url = window.URL.createObjectURL(new Blob([res as any], { type: "application/force-download;charset=utf-8" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${t("userSystem.analysisStatisticsDownload")}.xls`);
      document.body.appendChild(link);
      link.click();
      link.onload = () => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      };
    });
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSearch = (params: any) => {
    setSearchData((prevData) => ({ ...prevData, [params.name]: params.value }));
    if ((params.name === "startOccurTime" || params.name === "endOccurTime") && params.value && params.value.format("YYYY-MM-DD") === "Invalid Date") {
      return;
    }
    setPage(0);
    handleGetAnalysisStatistics({ pageNo: 1, search: { ...searchData, [params.name]: params.value } });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSearch({ name: "keyword", value: event.target.value });
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    handleGetAnalysisStatistics({ pageNo: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    handleGetAnalysisStatistics({ pageNo: 0, pageSize: parseInt(event.target.value, 10) });
  };

  const handleGetAnalysisStatistics = async ({
    pageNo = page + 1,
    pageSize = rowsPerPage,
    sortOrderAsc = sortOrder[sortByField] === "asc",
    sortByFields = sortByField,
    search = searchData } = {}) => {
    setIsLoading(true);
    let params = { ...search };
    if (search.startOccurTime) {
      params.startOccurTime = search.startOccurTime.format("YYYY-MM-DD");
    }
    if (search.endOccurTime) {
      params.endOccurTime = search.endOccurTime.format("YYYY-MM-DD");
    }
    const res: any = await getAnalysisStatistics({ ...params, pageNo, pageSize, sortOrderAsc, sortByField: sortByFields, });
    setIsLoading(false);
    if (res.code !== 200) return;
    setAnalysisStatisticsData(res.data.rows);
    setTotalCount(res.data.totalCount * 1 || 0);
    setTotalStatistic(res.data.totalStatistic)
  };

  useEffect(() => {
    handleGetAnalysisStatistics();
  }, []);

  return (
    <div className={styles.container}>
      <Toolbar style={{ justifyContent: "space-between", borderBottom: "1px solid #ccc", marginBottom: "5px", padding: "0" }}>
        <div>
          <Button
            size="small"
            variant="outlined"
            color="success"
            style={{ padding: "4px 10px", marginRight: "10px" }}
            onClick={hanldeDownload}
          >
            {t("single.buttons.run")}
          </Button>
        </div>

        <IconButton
          color="success"
          onClick={toggleFilters}
          style={{ color: showFilters ? "#2e7d32" : "rgba(0, 0, 0, 0.54)" }}
        >
          <span className="material-icons">filter_list</span>
        </IconButton>
      </Toolbar>

      {showFilters && (
        <Box style={{ display: "flex", alignItems: "center", padding: "8px", backgroundColor: "#f5f5f5", justifyContent: "space-between" }}>
          <Box style={{ display: "flex", flex: 1, alignItems: "center" }}>
            <TextField
              color="success"
              variant="outlined"
              size="small"
              placeholder={t("userSystem.filterByKeyword")}
              value={searchIpt}
              onChange={(e) => setSearchIpt(e.target.value)}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="material-icons">filter_list</span>
                  </InputAdornment>
                ),
                endAdornment: searchIpt && (
                  <InputAdornment position="end">
                    <span
                      className="material-icons cursor-pointer"
                      onClick={() => {
                        setSearchIpt("");
                        searchData.keyword && handleSearch({ name: "keyword", value: "" });
                      }}
                    >
                      close
                    </span>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "250px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0",
                },
                "& .MuiOutlinedInput-input,& .MuiOutlinedInput-input::placeholder": {
                  fontSize: "14px",
                },
              }}
            />
            <StartTimeDropdown
              defaultTime={searchData.startOccurTime}
              onSave={(value) => handleSearch({ name: "startOccurTime", value })}
            />
            <EndTimeDropdown
              defaultTime={searchData.endOccurTime}
              onSave={(value) => handleSearch({ name: "endOccurTime", value })}
            />
          </Box>
          <IconButton
            onClick={() => setShowFilters(false)}
            aria-label="clear"
            color="default"
          >
            <span className="material-icons">clear</span>
          </IconButton>
        </Box>
      )}

      <div style={{ flex: 1, overflow: "auto" }}>
        <Table
          stickyHeader
          aria-label="sticky  table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortByField === "start_date"}
                  onClick={() => {
                    handleGetAnalysisStatistics({ sortOrderAsc: !(sortOrder.start_date === "asc"), sortByFields: "start_date" })
                    setSortByField("start_date")
                    let sort: any = {
                      ...sortOrder,
                      start_date: sortOrder.start_date === "asc" ? "desc" : "asc"
                    }
                    setSortOrder(sort)
                  }}
                  direction={sortOrder.start_date}
                >

                  {t("userSystem.startDate")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortByField === "end_date"}
                  onClick={() => {
                    handleGetAnalysisStatistics({ sortOrderAsc: !(sortOrder.end_date === "asc"), sortByFields: "end_date" })
                    setSortByField("end_date")
                    let sort: any = {
                      ...sortOrder,
                      end_date: sortOrder.end_date === "asc" ? "desc" : "asc"
                    }
                    setSortOrder(sort)
                  }}
                  direction={sortOrder.end_date}
                >

                  {t("userSystem.endDate")}
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ minWidth: "140px" }}>
                <TableSortLabel
                  active={sortByField === "tenant_name"}
                  onClick={() => {
                    handleGetAnalysisStatistics({ sortOrderAsc: !(sortOrder.tenant_name === "asc"), sortByFields: "tenant_name" })
                    setSortByField("tenant_name")
                    let sort: any = {
                      ...sortOrder,
                      tenant_name: sortOrder.tenant_name === "asc" ? "desc" : "asc"
                    }
                    setSortOrder(sort)
                  }}
                  direction={sortOrder.tenant_name}
                >
                  {t("userSystem.tenantName")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortByField === "user_account"}
                  onClick={() => {
                    handleGetAnalysisStatistics({ sortOrderAsc: !(sortOrder.user_account === "asc"), sortByFields: "user_account" })
                    setSortByField("user_account")
                    let sort: any = {
                      ...sortOrder,
                      user_account: sortOrder.user_account === "asc" ? "desc" : "asc"
                    }
                    setSortOrder(sort)
                  }}
                  direction={sortOrder.user_account}
                >

                  {t("userSystem.userAccount")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortByField === "layer_amount"}
                  onClick={() => {
                    handleGetAnalysisStatistics({ sortOrderAsc: !(sortOrder.layer_amount === "asc"), sortByFields: "layer_amount" })
                    setSortByField("layer_amount")
                    let sort: any = {
                      ...sortOrder,
                      layer_amount: sortOrder.layer_amount === "asc" ? "desc" : "asc"
                    }
                    setSortOrder(sort)
                  }}
                  direction={sortOrder.layer_amount}
                >

                  {t("userSystem.layerAmount")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortByField === "single_lookup_count"}
                  onClick={() => {
                    handleGetAnalysisStatistics({ sortOrderAsc: !(sortOrder.single_lookup_count === "asc"), sortByFields: "single_lookup_count" })
                    setSortByField("single_lookup_count")
                    let sort: any = {
                      ...sortOrder,
                      single_lookup_count: sortOrder.single_lookup_count === "asc" ? "desc" : "asc"
                    }
                    setSortOrder(sort)
                  }}
                  direction={sortOrder.single_lookup_count}
                >

                  {t("userSystem.singleLookupCount")}
                </TableSortLabel>
              </TableCell>
              <TableCell >
                <TableSortLabel
                  active={sortByField === "batch_lookup_count"}
                  onClick={() => {
                    handleGetAnalysisStatistics({ sortOrderAsc: !(sortOrder.batch_lookup_count === "asc"), sortByFields: "batch_lookup_count" })
                    setSortByField("batch_lookup_count")
                    let sort: any = {
                      ...sortOrder,
                      batch_lookup_count: sortOrder.batch_lookup_count === "asc" ? "desc" : "asc"
                    }
                    setSortOrder(sort)
                  }}
                  direction={sortOrder.batch_lookup_count}
                >
                  {t("userSystem.batchLookupCount")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortByField === "total_location_amount"}
                  onClick={() => {
                    handleGetAnalysisStatistics({ sortOrderAsc: !(sortOrder.total_location_amount === "asc"), sortByFields: "total_location_amount" })
                    setSortByField("total_location_amount")
                    let sort: any = {
                      ...sortOrder,
                      total_location_amount: sortOrder.total_location_amount === "asc" ? "desc" : "asc"
                    }
                    setSortOrder(sort)
                  }}
                  direction={sortOrder.total_location_amount}
                >
                  {t("userSystem.amountOfLocations")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortByField === "total_lookup_amount"}
                  onClick={() => {
                    handleGetAnalysisStatistics({ sortOrderAsc: !(sortOrder.total_lookup_amount === "asc"), sortByFields: "total_lookup_amount" })
                    setSortByField("total_lookup_amount")
                    let sort: any = {
                      ...sortOrder,
                      total_lookup_amount: sortOrder.total_lookup_amount === "asc" ? "desc" : "asc"
                    }
                    setSortOrder(sort)
                  }}
                  direction={sortOrder.total_lookup_amount}
                >
                  {t("userSystem.grandTotalOfLookups")}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={10}>
                  <div className={styles.loading}>
                    <div className="loading loading-spinner loading-lg"></div>
                    <div>{t("layers.loading")}</div>
                  </div>
                </TableCell>
              </TableRow>
            ) : (<>
              <TableRow>
                <TableCell colSpan={2}>{t("userSystem.grandTotal")}</TableCell>
                <TableCell>{totalStatistic.tenant_count}</TableCell>
                <TableCell>{totalStatistic.user_count}</TableCell>
                <TableCell>{totalStatistic.layer_amount}</TableCell>
                <TableCell>{totalStatistic.single_lookup_count}</TableCell>
                <TableCell>{totalStatistic.batch_lookup_count}</TableCell>
                <TableCell>{totalStatistic.total_location_amount}</TableCell>
                <TableCell>{totalStatistic.total_lookup_amount}</TableCell>
              </TableRow>
              {analysisStatisticsData.map((item: any, index: number) => (
                <TableRow
                  key={item.id}
                  className={styles.row}
                >
                  <TableCell>{item.start_date}</TableCell>
                  <TableCell>{item.end_date}</TableCell>
                  <TableCell>{item.tenant_name}</TableCell>
                  <TableCell>{item.user_account}</TableCell>
                  <TableCell>{item.layer_amount}</TableCell>
                  <TableCell>{item.single_lookup_count}</TableCell>
                  <TableCell>{item.batch_lookup_count}</TableCell>
                  <TableCell>{item.total_location_amount}</TableCell>
                  <TableCell>{item.total_lookup_amount}</TableCell>
                </TableRow>
              ))}
            </>

            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("userSystem.rowsPerPage")}
      />
    </div >
  );
}

export default AnalysisStatistics;
