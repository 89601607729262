import Box from "@mui/material/Box";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export default function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ height: "calc(100% - 100px)" }}
		>
			{value === index && <Box sx={{ p: 3, height: "100%" }}>{children}</Box>}
		</div>
	);
}
