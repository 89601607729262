import {
	Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,
	FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Stack, TextField
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { addTenant, updateTenant } from "api/layer";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/zh";
import { AppAction, AppContext } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./tenant-add-modal.module.scss";

interface Props {
	open: boolean;
	onClose: () => void;
	onSave: () => void;
	data?: FormData;
	roles: any[];
}

interface FormData {
	id: string;
	tenantCode: string;
	tenantName: string;
	userCount: number;
	// qwningTenant: string;
	role: string;
	roleNames: string;
	roleIds: string[];
	roles: any[];
	status: string;
	startTime: any;
	endTime: any;
}

function TenantAddModal(props: Props) {
	const [t] = useTranslation();
	const [context, dispatch] = useContext(AppContext);
	const [formData, setFormData] = useState<FormData>(
		props.data
			? { ...props.data, startTime: dayjs(props.data.startTime), endTime: dayjs(props.data.endTime) }
			: {
				id: "",
				tenantCode: "",
				tenantName: "",
				userCount: 0,
				// qwningTenant: "1",
				role: "",
				roleNames: "",
				roles: [],
				roleIds: [],
				status: "",
				startTime: null,
				endTime: null,
			}
	);
	const [isError, setIsError] = useState(false);

	const handleChange = (event: any) => {
		const { name, value, type, checked } = event.target;
		console.log(value, name);

		if (name === "roleNames") {
			setFormData((prevData) => ({ ...prevData, [name]: value.join("|") }));
			return;
		}

		// if (type === "checkbox" && name === "status") {
		// 	setFormData((prevData) => ({ ...prevData, [name]: checked ? "3" : "1" }));
		// 	return;
		// }

		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const close = () => {
		props.onClose();
	};

	const handleSave = () => {
		let data = { ...formData };
		if (!data.tenantName || data.userCount === 0 || !data.roleNames || !data.startTime || !data.endTime) {
			setIsError(true);
			return;
		} else {
			setIsError(false);
		}
		data.startTime = formData.startTime.format("YYYY-MM-DD");
		data.endTime = formData.endTime.format("YYYY-MM-DD");

		if (data.roleNames) {
			const roleNames = data.roleNames.split("|");
			if (roleNames.length > 0) {
				// data.roles = roleNames.map((roleName: string) => {
				// 	const role = props.roles.find((r: any) => r.roleName === roleName);
				// 	return role;
				// });
				data.roleIds = roleNames.map((roleName: string) => {
					const role = props.roles.find((r: any) => r.roleName === roleName);
					return role.id;
				});
			}
		}

		if (props.data) {
			updateTenant(data)
				.then((res: any) => {
					if (res && res.code === 200) {
						props.onClose();
						dispatch({
							type: AppAction.setSnackBarParams,
							payload: {
								title: "editTenant",

								message: "editTenantSuccess",
							},
						});
					} else {
						dispatch({
							type: AppAction.setSnackBarParams,
							payload: {
								title: "editTenant",
								// text: "ccc",
								titleIconColor: "yellow",
								titleIcon: "warning",
								resMessage: res.message,
							},
						});
					}
				})
				.finally(() => {
					props.onSave();
				});
		} else {
			addTenant(data)
				.then((res: any) => {
					if (res && res.code === 200) {
						props.onClose();
						dispatch({
							type: AppAction.setSnackBarParams,
							payload: {
								title: "addTenant",
								message: "addTenantSuccess",
							},
						});
					} else {
						dispatch({
							type: AppAction.setSnackBarParams,
							payload: {
								title: "addTenant",
								// text: "ccc",
								titleIconColor: "yellow",
								titleIcon: "warning",
								resMessage: res.message,
							},
						});
					}
				})
				.finally(() => {
					props.onSave();
				});
		}
	};

	useEffect(() => {
		//// 因为mui的日期组件Previous month和Previous month无法进行中英翻译 删除上月和下月切换按钮的title属性
		const observer = new MutationObserver((mutationsList) => {
			mutationsList.forEach((mutation) => {
				if (mutation.type === "childList") {
					const buttons = document.querySelectorAll(".MuiPickersArrowSwitcher-root button");
					buttons.forEach((button) => {
						button.removeAttribute("title");
					});
				}
			});
		});

		observer.observe(document.body, { childList: true, subtree: true });

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<Dialog
			open={props.open}
			onClose={close}
		>
			<DialogTitle
				sx={{
					fontWeight: 600,
					fontSize: "16px",
					opacity: 0.79,
					color: "#333",
					width: "400px",
				}}
			>
				{props.data ? t("userSystem.editTenant") : t("userSystem.addTenant")}
			</DialogTitle>
			<DialogContent sx={{ paddingTop: "10px!important" }}>
				<Stack spacing={{ xy: 1, sm: 2 }}>
					<TextField
						name="tenantCode"
						value={formData.tenantCode}
						onChange={handleChange}
						label={t("userSystem.tenantCode")}
						variant="outlined"
						color="success"
					/>
					<TextField
						required
						name="tenantName"
						value={formData.tenantName}
						error={isError && !formData.tenantName}
						onChange={handleChange}
						label={t("userSystem.tenantName")}
						variant="outlined"
						color="success"
					/>
					<TextField
						required
						name="userCount"
						value={formData.userCount}
						error={isError && !formData.userCount}
						onChange={handleChange}
						label={t("userSystem.maxUserCount")}
						variant="outlined"
						color="success"
						type="number"
					/>

					<FormControl
						fullWidth
						error={isError && formData.roleNames.length === 0}
					>
						<InputLabel color="success" required>{t("userSystem.roleName")}</InputLabel>
						<Select
							multiple
							value={(formData.roleNames && formData.roleNames.split("|")) || []}
							onChange={handleChange}
							name="roleNames"
							label={t("userSystem.roleName")}
							renderValue={(selected) => selected.join("|")}
							color="success"
						>
							{props.roles &&
								props.roles.map((role: any) => (
									<MenuItem
										key={role.id}
										value={role.roleName}
									>
										<ListItemText primary={role.roleName} />
									</MenuItem>
								))}
						</Select>
					</FormControl>

					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={context.language === "cn" ? "zh" : "en"}
					>
						<DatePicker
							label={t("userSystem.startDate") + " *"}
							value={formData.startTime}
							format="YYYY-MM-DD"
							onChange={(event) => {
								handleChange({ target: { name: "startTime", value: event } });
							}}
							sx={{
								"& .MuiInputLabel-root": {
									color: isError && !formData.startTime ? "#d32f2f" : "",
								},
								"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
									borderColor: isError && !formData.startTime ? "#d32f2f" : "",
								},
								"& .MuiInputLabel-root.Mui-focused": {
									color: isError && !formData.startTime ? "#d32f2f" : "green",
								},
								"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
									borderColor: isError && !formData.startTime ? "#d32f2f" : "green",
								},
							}}
						/>
						<DatePicker
							label={t("userSystem.endDate") + " *"}
							value={formData.endTime}
							format="YYYY-MM-DD"
							onChange={(event) => {
								handleChange({ target: { name: "endTime", value: event } });
							}}
							minDate={formData.startTime}
							sx={{
								"& .MuiInputLabel-root": {
									color: isError && !formData.endTime ? "#d32f2f" : "",
								},
								"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
									borderColor: isError && !formData.endTime ? "#d32f2f" : "",
								},
								"& .MuiInputLabel-root.Mui-focused": {
									color: isError && !formData.endTime ? "#d32f2f" : "green",
								},
								"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
									borderColor: isError && !formData.endTime ? "#d32f2f" : "green",
								},
							}}
						/>
					</LocalizationProvider>
				</Stack>

				{props.data && <FormControl>
					<div style={{ display: "flex", alignItems: "center" }}>
						<FormControlLabel
							label={t('userSystem.editStatus')}
							control={<Checkbox checked={formData.status === "3"}
								onChange={handleChange} name="status" color="success" />}
						/>
					</div>
				</FormControl>}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={close}
					color="success"
				>
					{t("locations.modal.cancel")}
				</Button>
				<Button
					onClick={handleSave}
					color="success"
					variant="contained"
				>
					{t("locations.add.save")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default TenantAddModal;
