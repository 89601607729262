import { MenuItem } from "./type";

export const buildTree = (data: MenuItem[]): MenuItem[] => {
	const map: { [key: string]: MenuItem } = {};
	data.forEach((item) => {
		map[item.id] = { ...item, children: [] };
	});

	const tree: MenuItem[] = [];
	data.forEach((item) => {
		const parent = map[item.parentId];
		if (parent) {
			if (!parent.children) {
				parent.children = [];
			}
			parent.children.push(map[item.id]);
		} else {
			tree.push(map[item.id]);
		}
	});

	return tree;
};
