import styles from "./multipleAgenda.module.scss";
import { AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { getCounts, getCheckedCounts } from "helpers/helpers";
import { colorByTiv } from "helpers/locations";

function MultipleAgenda() {
    const [context, dispatch] = useContext(AppContext);
    const [t] = useTranslation();

    return (
        <div className={styles.multipleAgenda}>
            <div className={styles.header}>
                <div className={styles.text}>{context.currentMultipleLocations!.name}</div>
            </div>
            <div>
                <div className={styles.subtitle}>({t("legend.showingLocations")} {getCheckedCounts(context.currentMultipleLocations!.items)}/{context.currentMultipleLocations?.itemCount})</div>
                <div className={styles.legend}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{
                                backgroundColor: "green",//colorByTiv(0),
                                opacity: '1',
                            }}
                        ></span>
                        100%({context.currentMultipleLocations?.itemCount})
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MultipleAgenda;
