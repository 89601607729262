import { MapContext } from "helpers/map";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./layer-switch.module.scss";

export default function LayerSwitch() {
	const [show, setShow] = useState<boolean>(false);
	const [current, setCurrent] = useState<string>("normal");

	const [satellite, setSatellite] = useState<AMap.TileLayer | null>(null);
	const [roadNet, setRoadNet] = useState<AMap.TileLayer | any>(null);

	const { AMap, map } = useContext(MapContext);

	const style: string[] = ["normal", "light", "dark", "fresh", "satellite"]; //, "roadnet"

	const [t] = useTranslation();

	const expand = (): void => {
		setShow(!show);
	};

	const changeLayer = (item: string): void => {
		setCurrent(item);
		if (item === "roadnet") {
			if (roadNet) {
				map.remove(roadNet);
				setRoadNet(null);
				return;
			}
			const rn = new AMap.TileLayer.RoadNet();
			setRoadNet(rn);
			map.add(rn);
			return;
		}
		if (satellite) {
			map.remove(satellite);
			setSatellite(null);
		}
		if (item === "satellite") {
			const satellite = new AMap.TileLayer.Satellite({ zIndex: 1 });
			map.add(satellite);
			setSatellite(satellite);
			return;
		}
		const styleName = "amap://styles/" + item;
		map.setMapStyle(styleName);
	};

	return (
		<div className={`${styles.screen} dropdown dropdown-left`}>
			<div
				onClick={expand}
				tabIndex={0}
				role="button"
				className="tooltip"
				data-tip={t("layerSwitch.Map Styles")}
			>
				<img
					// title={t("layerSwitch.Map Styles")}
					alt={current}
					src={`/layer_icon_${current}.png`}
				/>
			</div>

			{show && (<div
				className={`${styles.download} dropdown-content bg-base-100 shadow`}
				tabIndex={0}
			>
				{style &&
					style.map((item, index) => (
						<div
							key={index}
							className="tooltip"
							data-tip={t(`layerSwitch.${item}`)}
						>
							<img
								// title={t(`layerSwitch.${item}`)}
								alt={item}
								src={`/layer_icon_${item}.png`}
								key={index}
								onClick={() => changeLayer(item)}
							/>
						</div>
					))}
			</div>)}
		</div>
	);
}
