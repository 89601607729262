import { AppContext } from "helpers/context";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Chart from "components/chart/chart";
import Svg from "components/svg/svg";
import styles from "./climate-multiple-results.module.scss";
import { ReactComponent as IconTable } from "assets/icon_table_th.svg";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { ReactComponent as NotAnalysisSvg } from "assets/notAnalysis.svg";
import { toNumber } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LinearProgress from "@mui/material/LinearProgress";

enum Tabs {
	SSP126 = "SSP1-2.6",
	SSP245 = "SSP2-4.5",
	SSP585 = "SSP5-8.5",
}

const BgColor = (num: number) => {
	if (num < 2) {
		return "rgb(181, 230, 162)";
	} else if (num >= 2 && num < 4) {
		return "rgb(218, 242, 208)";
	} else if (num >= 4 && num < 6) {
		return "rgb(255, 255, 204)";
	} else if (num >= 6 && num < 8) {
		return "rgb(255, 199, 206)";
	} else {
		return "rgb(204, 0, 0)";
	}
};

export default function ClimateMultipleResults() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const [activeLegend, setActiveLegend] = useState(0);
	const [riskValue, setRiskValue] = useState<string>(Tabs.SSP126);
	const [yearValue, setYearValue] = useState<string>("2030");
	const [chartOption, setChartOption] = useState<any>(undefined);
	const [chartHeight, setChartHeight] = useState(0);
	const [tableDatas, setTableDatas] = useState<any[]>([]);
	const [noData, setNoData] = useState<any[]>([]);
	const [sortData, setSortData] = useState<any[]>([]);
	const [active, setActive] = useState<"intensity" | "value" | "share">("intensity");
	const [direction, setDirection] = useState<{ intensity: "asc" | "desc"; value: "asc" | "desc"; share: "asc" | "desc" }>({ intensity: "asc", value: "asc", share: "asc" });

	const handleSort = (property: "intensity" | "value" | "share") => {
		if (!tableDatas.length) {
			return;
		}

		if (!property) {
			return;
		}

		setActive(property);
		direction[property] = direction[property] === "asc" ? "desc" : "asc";
		setDirection(direction);

		handleSetSortData(JSON.parse(JSON.stringify(tableDatas)), property);
	};

	const handleSetSortData = (tableData: any, property = active, activeIndex = activeLegend) => {
		if (!tableData || !tableData.length) return;
		let sortArr = [...tableData];
		if (sortArr.length === 0) return;
		let key = property;
		if (key === "share") {
			key = "value";
		}
		sortArr[activeIndex].sort((a: any, b: any) => {
			if (!a || !b) {
				return 0;
			}
			const aValue = a?.[key];
			const bValue = b?.[key];
			if (aValue === undefined) {
				return direction[active] === "asc" ? -1 : 1;
			}
			if (bValue === undefined) {
				return direction[active] === "asc" ? 1 : -1;
			}
			if (aValue < bValue) {
				return direction[active] === "asc" ? 1 : -1;
			}
			if (aValue > bValue) {
				return direction[active] === "asc" ? -1 : 1;
			}
			return 0;
		});
		setSortData([...sortArr]);
	};

	const noDataText = () => {
		if (noData.length > 0) {
			let text = t("multiple.noData");
			noData.forEach((item: any, index: number) => {
				if (index === 0) {
					text += t(`layerId.${item.layerId}`);
				} else if (index === noData.length - 1) {
					text += t("multiple.and") + t(`layerId.${item.layerId}`);
				} else {
					text += t("multiple.comma") + t(`layerId.${item.layerId}`);
				}
			});
			return text + t("multiple.period");
		}
	};

	const handleChangeRisk = (e: any) => {
		setRiskValue(e.target.value);
		getData();
	};

	const handleChangeYear = (e: any) => {
		setYearValue(e.target.value);
		getData();
	};

	const handleLegendClick = (index: number) => {
		setActiveLegend(index);
		handleSetSortData(JSON.parse(JSON.stringify(tableDatas)), active, index);
	};

	const toFixedTwo = (num: number) => {
		return num % 1 === 0 ? num : num.toFixed(2);
	};

	const handleSwitchValueLabel = (value: string) => {
		if (value === "Outside" || value === "No Data" || value === "No_Data" || value === "No Observation" || value === "Negligible") return value;
		switch (true) {
			case parseInt(value) < 2:
				return "negligible";
			case parseInt(value) >= 2 && parseInt(value) < 4:
				return "minor";
			case parseInt(value) >= 4 && parseInt(value) < 6:
				return "moderate";
			case parseInt(value) >= 6 && parseInt(value) < 8:
				return "significant";
			case parseInt(value) >= 8:
				return "severe";
			default:
				return value;
		}
	};

	const getData = () => {
		if (context.climateMultipleAnalysisResult && context.climateMultipleAnalysisResult.length > 0
			&& context.climateMultipleAnalysedLegends && context.climateMultipleAnalysedLegends.length > 0
			&& context.climateMultipleAnalysisStatus !== "error" && context.climateMultipleAnalysisStatus !== "running") {
			setActiveLegend(0);

			let arr: any = [];
			context.climateMultipleAnalysedLegends.forEach((legend: any, index: number) => {
				arr.push([]);
				context.climateMultipleAnalysisResult!.forEach((item: any) => {
					let scenario = item.climateRiskScores.find((s: any) => s.index === legend.id);
					if (scenario) {
						let scenarioData = scenario.scenarios.find((s: any) => s.name === riskValue);
						if (scenarioData) {
							let band = scenarioData.bandGroups[0].bands.find((band: any) => band.name === yearValue);
							if (band) {
								arr[index].push({
									value: 1,
									valueLabel: band.value === "" || band.value === "NaN" ? "" : handleSwitchValueLabel(band.value),
									intensity: band.value === "" || isNaN(band.value) ? 0 : band.value,
									layerId: legend.id,
									itemStyle: {
										color: band.value === "" || isNaN(band.value) ? "grey" : BgColor(band.value),
										opacity: band.value === "" || isNaN(band.value) ? 0.5 : 1,
									},
								});
							}
						}
					}
				});
			});

			let data: any = arr.map((item: any) => {
				return item.sort((a: any, b: any) => {
					return toNumber(b.intensity) - toNumber(a.intensity);
				});
			});

			let tableData: any = [],
				noData: any = [];

			data.forEach((dataItem: any) => {
				const valueMap: any = {};
				if (dataItem && dataItem.length > 0) {
					dataItem.forEach((item: any) => {
						const label = item.valueLabel;
						if (valueMap[label] === undefined) {
							valueMap[label] = 1; // First occurrence
							item.value = dataItem.filter((i: any) => i.valueLabel === label).length; // Count total occurrences
						} else {
							item.value = 0; // Subsequent occurrences
						}
					});

					if (
						(dataItem[0].valueLabel === "Outside" || dataItem[0].valueLabel === "No Data" || dataItem[0].valueLabel === "No_Data" || dataItem[0].valueLabel === "No Observation" || dataItem[0].valueLabel === "" || dataItem[0].valueLabel === "Negligible") &&
						dataItem[0].value === dataItem.length
					) {
						noData.push(dataItem[0]);
					} else {
						tableData.push(dataItem);
					}
				}
			});
			setTableDatas(tableData);
			setNoData(noData);
			handleSetSortData(JSON.parse(JSON.stringify(tableData)));

			let series: any = [];
			let Xnum = tableData[0] ? tableData[0].length : 0;
			let Ynum = tableData.length;
			setChartHeight(Ynum);
			for (let i = 0; i < Xnum; i++) {
				let dataArr = [];
				for (let j = 0; j < Ynum; j++) {
					if (tableData[j]) {
						dataArr.unshift(tableData[j][i]);
					}
				}
				series.push({
					name: i,
					type: "bar",
					stack: "total",
					barWidth: 24,
					data: dataArr,
				});
			}

			const option: any = {
				tooltip: {
					confine: true,
					trigger: "item",
					axisPointer: {
						type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
					},
					backgroundColor: "rgba(0,0,0,0.6)",
					borderColor: "rgba(0,0,0,0.6)",
					formatter: function (params: any, ticket: any) {
						return `<div>
                            <p style="color: #fff;font-size:14px;">${params.data.valueLabel ? t("crsLegend." + params.data.valueLabel) : ""}</p>    
                            <p style="color: #fff;font-size:14px;margin: 5px 0;">${t("layerId." + params.data.layerId)}</p>    
                            <p style="display: flex;justify-content: space-between;align-items: center;font-size: 12px;"><span style="color: rgba(255,255,255,0.8);margin-right:10px;">${t(
							"locations.table.locations"
						)}</span> <span style="color: #fff">${params.data.value}</span></p>    
                            <p style="display: flex;justify-content: space-between;align-items: center;font-size: 12px;"><span style="color: rgba(255,255,255,0.8);margin-right:10px;">${t(
							"locations.shareOfNumberOfLocations"
						)}</span> <span style="color: #fff">${toFixedTwo((params.data.value / Xnum) * 100)}%</span></p>    
                        </div>`;
					},
				},
				grid: {
					left: 10,
					right: 10,
					bottom: 10,
					top: 10,
					containLabel: true,
				},
				xAxis: {
					type: "value",
					axisLine: {
						show: true,
					},
					minInterval: 1,
					max: Xnum,
				},
				yAxis: {
					type: "category",
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: false,
					},
					axisLine: {
						show: true,
						onZero: true,
						lineStyle: {
							color: "#fff",
							shadowOffsetX: -1,
							shadowColor: "#333",
						},
					},
				},
				series: series,
			};

			setChartOption(option);
		}
	};

	useEffect(() => {
		getData();
	}, [context.climateMultipleAnalysedLegends, context.climateMultipleAnalysisResult]);

	if (context.climateMultipleAnalysisStatus && context.climateMultipleAnalysisStatus === "error") {
		return (
			<div className="h-full flex flex-col items-center justify-center">
				<div
					className="text-center"
					style={{ color: "gray" }}
				>
					<span className="material-icons">error_outline</span>
				</div>
				<div className="text-[14px]">{t("single.graunchTitle")}</div>
				<div dangerouslySetInnerHTML={{ __html: t("single.graunchContent") }}></div>
			</div>
		);
	}

	if (!context.climateMultipleAnalysedLegends || context.climateMultipleAnalysedLegends.length === 0) {
		return (
			<div className="h-full flex flex-col items-center justify-center">
				<NotAnalysisSvg className="w-[60px] h-[60px] mx-auto mb-4" />
				<div style={{ color: "var(--gray-text)" }}>{t("single.notMultipleAnalysis")}</div>
			</div>
		);
	}

	if (context.climateMultipleAnalysisStatus && context.climateMultipleAnalysisStatus === "running") {
		return (
			<div className="h-full flex flex-col items-center justify-center">
				<LinearProgress
					color="success"
					style={{ width: "40%", marginBottom: "10px" }}
				/>
				<div>{t("multiple.climateAlalyseLoadingContent")}</div>
			</div>
		);
	}

	return (
		<>
			<div className="my-[10px]">
				<span>{t("multiple.pathway")}: </span>
				<Select
					value={riskValue}
					className={`${styles.select} w-[100px] mr-[10px]`}
					onChange={handleChangeRisk}
					color="success"
				>
					<MenuItem value="SSP1-2.6">SSP 1-2.6 </MenuItem>
					<MenuItem value="SSP2-4.5">SSP 2-4.5</MenuItem>
					<MenuItem value="SSP5-8.5">SSP 5-8.5</MenuItem>
				</Select>
				<span> {t("multiple.year")}: </span>
				<Select
					value={yearValue}
					className={`${styles.select} w-[78px]`}
					onChange={handleChangeYear}
					color="success"
				>
					<MenuItem value="2030">2030</MenuItem>
					<MenuItem value="2035">2035</MenuItem>
					<MenuItem value="2040">2040</MenuItem>
					<MenuItem value="2045">2045</MenuItem>
					<MenuItem value="2050">2050</MenuItem>
					<MenuItem value="2055">2055</MenuItem>
					<MenuItem value="2060">2060</MenuItem>
					<MenuItem value="2070">2070</MenuItem>
					<MenuItem value="2085">2085</MenuItem>
				</Select>
			</div>
			<div className={styles.resultsMultiple}>
				<div>
					{tableDatas.length > 0 && (
						<>
							<div
								className={styles.chart}
								style={{ height: chartHeight * 63 + "px" }}
							>
								<div className={styles.legendIcon}>
									{tableDatas.map((tableDataItem: any, index: number) => {
										return (
											<IconButton
												key={index}
												onClick={() => {
													handleLegendClick(index);
												}}
												className={`${activeLegend === index ? styles.activeLegend : ""}`}
											>
												{Svg({ icon: tableDataItem[0].layerId })()}
											</IconButton>
										);
									})}
								</div>
								<Chart option={chartOption} />
							</div>
							<div className="text-center text-[12px]">{t("multiple.chartTitle")}</div>
						</>
					)}

					{noData.length > 0 && <div className="m-2">{noDataText()}</div>}
					{(tableDatas[activeLegend] || noData.length > 0) && (
						<div>
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px" }}>
								<p className="m-2 font-bold">{t(`layerId.${tableDatas[activeLegend] ? tableDatas[activeLegend][0].layerId : noData[0].layerId}`)}</p>
								{/* <p className="my-3 font-medium">{t(`layerId.${tableData[activeLegend] ? tableData[activeLegend][0].layerId : noData[0].layerId}`)}</p> */}
								<div style={{ padding: "6px 10px", borderRadius: "5px", backgroundColor: "var(--green-hover)", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)" }}>
									<IconTable />
								</div>
							</div>
							<Paper
								sx={{ width: "100%", boxShadow: "none" }}
								className={styles.table}
							>
								<TableContainer sx={{ height: "100%" }}>
									<Table
										stickyHeader
										aria-label="sticky table"
										size="small"
									>
										<TableHead>
											<TableRow className={styles.row}>
												<TableCell align="left">
													<TableSortLabel
														active={active === "intensity"}
														onClick={() => handleSort("intensity")}
														direction={direction.intensity}
													>
														{context.language === "en" ? "Score in " : ""}
														{yearValue}
													</TableSortLabel>
												</TableCell>
												<TableCell align="right">
													<TableSortLabel
														active={active === "value"}
														onClick={() => handleSort("value")}
														direction={direction.value}
													>
														{t("locations.table.locations")}
													</TableSortLabel>
												</TableCell>
												<TableCell align="right">
													<TableSortLabel
														active={active === "share"}
														onClick={() => handleSort("share")}
														direction={direction.share}
													>
														{t("locations.table.share")}
													</TableSortLabel>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{sortData[activeLegend] &&
												sortData[activeLegend].map(
													(item: any, index: number) =>
														item.value > 0 && (
															<TableRow key={index}>
																<TableCell align="left">
																	<div style={{ display: "flex", alignItems: "center" }}>
																		<span
																			style={{
																				flexShrink: 0,
																				width: "16px",
																				height: "16px",
																				marginRight: "5px",
																				display: "inline-block",
																				backgroundColor: item.itemStyle.color,
																				opacity: item.itemStyle.opacity,
																			}}
																		></span>
																		{item.valueLabel ? t("crsLegend." + item.valueLabel) : ""}
																	</div>
																</TableCell>
																<TableCell align="right">{item.value}</TableCell>
																<TableCell align="right">{toFixedTwo((item.value / sortData[activeLegend].length) * 100)}%</TableCell>
															</TableRow>
														)
												)}
											<TableRow style={{ fontWeight: "bold", height: "50px" }}>
												<TableCell>{t("multiple.total")}</TableCell>
												<TableCell align="right">{sortData[activeLegend] ? sortData[activeLegend].length : 0}</TableCell>
												<TableCell align="right">100%</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
