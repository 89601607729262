import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { AppAction, AppContext } from "helpers/context";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { upgradeRoleQuotasPermission, queryQuotasIdsByRoleId } from "../../../../api/layer";
import { RoleType } from "../type";
import LimitControl from "./limit-control/limit-control";
import styles from "./role-quota-modal.module.scss";

interface Props {
	onClose: () => void;
	data?: RoleType;
}

const RoleQuotaModal: React.FC<Props> = (props) => {
	const [context, dispatch] = useContext(AppContext);

	const [t] = useTranslation();

	const [limitOption1, setLimitOption1] = useState<string>("unlimited");
	const [limitCount1, setLimitCount1] = useState<number>(0);
	const [limitOption2, setLimitOption2] = useState<string>("unlimited");
	const [limitCount2, setLimitCount2] = useState<number>(0);
	const [limitOption3, setLimitOption3] = useState<string>("unlimited");
	const [limitCount3, setLimitCount3] = useState<number>(0);

	const [loading, setLoading] = useState<boolean>(false);

	const initData = () => {
		if (!props.data || !props.data.id) {
			return;
		}

		const roleId = props.data.id;
		if (!roleId) {
			return;
		}

		setLoading(true);

		queryQuotasIdsByRoleId({ roleId })
			.then((res) => {
				if (!res.data) {
					return;
				}

				const quotas = res.data;
				if (!quotas || quotas.length === 0) {
					return;
				}

				const quota1 = quotas.find((q: any) => q.quotasId === "102103");
				const quota2 = quotas.find((q: any) => q.quotasId === "103101");
				const quota3 = quotas.find((q: any) => q.quotasId === "103104");

				setLimitCount1(quota1.quantity || 0);
				setLimitCount2(quota2.quantity || 0);
				setLimitCount3(quota3.quantity || 0);

				if (quota1 && quota1.quantity !== -1) {
					setLimitOption1("limited");
				} else {
					setLimitOption1("unlimited");
				}

				if (quota2 && quota2.quantity !== -1) {
					setLimitOption2("limited");
				} else {
					setLimitOption2("unlimited");
				}

				if (quota3 && quota3.quantity !== -1) {
					setLimitOption3("limited");
				} else {
					setLimitOption3("unlimited");
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSave = () => {
		if (!props.data || !props.data.id) {
			return;
		}

		const roleId = props.data.id;
		if (!roleId) {
			return;
		}

		const quotasList = [];

		if (limitOption1) {
			quotasList.push({
				quantity: limitOption1 === "unlimited" ? -1 : limitCount1,
				quotasId: "102103",
				// quotaType: limitOption1,
			});
		}

		if (limitOption2) {
			quotasList.push({
				quantity: limitOption2 === "unlimited" ? -1 : limitCount2,
				quotasId: "103101",
				// quotaType: limitOption2,
			});
		}

		if (limitOption3) {
			quotasList.push({
				quantity: limitOption3 === "unlimited" ? -1 : limitCount3,
				quotasId: "103104",
				// quotaType: limitOption3,
			});
		}

		const param = {
			quotasList,
			roleId,
		};

		setLoading(true);

		upgradeRoleQuotasPermission(param)
			.then((resp: any) => {
				if (!resp || resp.code !== 200) {
					dispatch({
						type: AppAction.setSnackBarParams,
						payload: {
							title: "updateQuota",
							titleIconColor: "yellow",
							titleIcon: "warning",
							resMessage: resp.message,
						},
					});
					return;
				}
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "updateQuota",
						message: "updateQuotaSuccess",
					},
				});
			})
			.catch((error) => {
				console.log(error);
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "updateQuota",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "updateQuotaError",
					},
				});
			})
			.finally(() => {
				props.onClose();
				setLoading(false);
			});
	};

	useEffect(() => {
		initData();
	}, [props.data]);

	return (
		<Dialog
			open={!!props.data}
			onClose={() => props.onClose()}
		>
			<DialogTitle
				sx={{
					fontWeight: 600,
					fontSize: "16px",
					opacity: 0.79,
					color: "#333",
					width: "600px",
				}}
			>
				{props.data && props.data.roleName}
			</DialogTitle>
			{loading ? (
				<div className={styles.loading}>
					<div className="loading loading-spinner loading-lg"></div>
					<div>{t("layers.loading")}</div>
				</div>
			) : (
				<DialogContent
					sx={{
						overflowY: "auto",
						"&::-webkit-scrollbar": { width: "8px" },
						"&::-webkit-scrollbar-thumb": { backgroundColor: "#888", borderRadius: "4px" },
						"&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#555" },
						"&::-webkit-scrollbar-track": { background: "#f1f1f1" },
					}}
				>
					<div className={styles.section}>
						<Typography style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "10px" }}>{t("permissionConfig.singlePointAnalysis")}</Typography>
						<div style={{ paddingLeft: "20px" }}>
							<Typography style={{ fontSize: "14px", color: "#333" }}>{t("permissionConfig.analysisCount")}</Typography>
							<LimitControl
								initialLimitOption={limitOption1}
								initialLimitCount={limitCount1}
								onLimitChange={setLimitOption1}
								onCountChange={setLimitCount1}
							/>
						</div>
					</div>
					<div className={styles.section}>
						<Typography style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "10px" }}>{t("permissionConfig.locationSet")}</Typography>
						<div style={{ paddingLeft: "20px" }}>
							<Typography style={{ fontSize: "14px", color: "#333" }}>{t("permissionConfig.addressCount")}</Typography>
							<LimitControl
								initialLimitOption={limitOption2}
								initialLimitCount={limitCount2}
								onLimitChange={setLimitOption2}
								onCountChange={setLimitCount2}
							/>

							<Typography style={{ fontSize: "14px", color: "#333" }}>{t("permissionConfig.analysisCount")}</Typography>
							<LimitControl
								initialLimitOption={limitOption3}
								initialLimitCount={limitCount3}
								onLimitChange={setLimitOption3}
								onCountChange={setLimitCount3}
							/>
						</div>
					</div>
				</DialogContent>
			)}
			<DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px 24px" }}>
				<Button
					onClick={initData}
					color="success"
				>
					{t("analyseModal.buttons.reset")}
				</Button>

				<div style={{ marginLeft: "auto" }}>
					<Button
						onClick={() => props.onClose()}
						color="success"
					>
						{t("locations.modal.cancel")}
					</Button>
					<Button
						onClick={handleSave}
						color="success"
						variant="contained"
					>
						{t("locations.add.save")}
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default RoleQuotaModal;
