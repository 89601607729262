import { Workbook } from "exceljs";
import { config } from "containers/multi-locations/config/config-hazard";
import { excelLocation } from "../location/excel-location";
import { tx } from "../common/excel-util";

export const excelSheetHazard = (context: any, t: any, workbook: Workbook) => {
	const worksheet = workbook.getWorksheet(2);

	const dataItem = excelLocation(context, worksheet);

	if (!dataItem || dataItem.length === 0) {
		return;
	}

	const hazardResult = context.hazardMultipleAnalysisResult;

	if (!hazardResult || hazardResult.length === 0) {
		return;
	}

	dataItem.forEach((item: any, i: number) => {
		const dataHazardFiltered = hazardResult.filter((f: any) => f.pointId === item.id);

		if (dataHazardFiltered.length === 0) {
			return null;
		}

		// console.log(i, item.id, dataHazardFiltered);

		dataHazardFiltered.forEach((e: any) => {
			hazardLayer(e, worksheet, i + 2, t);
			hazardDetail(e, worksheet, i + 2, t);
			hazardInstance(e, worksheet, i + 2, t);
			hazardHailAdditionalDetails(e, worksheet, i + 2, t);
		});
	});
};

const hazardLayer = (e: any, worksheet: any, rowNum: number, t: any) => {
	const col = config[e.layerId];
	const value = e.valueLabel;
	tx(col, value, worksheet, rowNum, t);
};

const hazardDetail = (analyzeItem: any, worksheet: any, rowNum: number, t: any) => {
	if (typeof analyzeItem !== "object") {
		return;
	}

	if (!analyzeItem.details) {
		return;
	}

	const keysInDetails = Object.keys(analyzeItem.details);

	if (!keysInDetails || keysInDetails.length === 0) {
		const keysInConfig = Object.keys(config);
		if (!keysInConfig || keysInConfig.length === 0) {
			return;
		}

		const keysInFilter = keysInConfig.filter((key) => key !== analyzeItem.layerId && key.includes(analyzeItem.layerId) && !key.includes("intensity"));

		if (!keysInFilter || keysInFilter.length === 0) {
			return;
		}

		keysInFilter.forEach((key) => {
			const c = config[key];
			const v = t("legend.No_Data");
			tx(c, v, worksheet, rowNum, t);
		});

		return;
	}

	keysInDetails.forEach((key) => {
		const details = analyzeItem.details as any;
		if (details[key] === null) {
			return;
		}

		const c = config[analyzeItem.layerId + "." + key];
		const v = details[key];
		tx(c, v, worksheet, rowNum, t);
	});
};

const hazardInstance = (analyzeItem: any, worksheet: any, rowNum: number, t: any) => {
	const c = config[analyzeItem.layerId + ".intensity"];
	const v = analyzeItem.intensity;
	tx(c, v, worksheet, rowNum, t);
};

const hazardHailAdditionalDetails = (analyzeItem: any, worksheet: any, rowNum: number, t: any) => {
	if (typeof analyzeItem !== "object") {
		return;
	}

	const hailAdditionalDetails = analyzeItem.hailAdditionalDetails as any;

	if (!hailAdditionalDetails) {
		return;
	}

	if (typeof hailAdditionalDetails !== "object") {
		return;
	}

	const set31 = hailAdditionalDetails["HailHazard-31-year-historical-data-set"];
	const set64 = hailAdditionalDetails["HailHazard-64-year-historical-data-set"];

	if (set31) {
		Object.keys(set31).forEach((key) => {
			const c = config[analyzeItem.layerId + ".31-set." + key];
			const v = set31[key];
			tx(c, v, worksheet, rowNum, t);
		});
	}

	if (set64) {
		Object.keys(set64).forEach((key) => {
			const c = config[analyzeItem.layerId + ".64-set." + key];
			const v = set64[key];
			tx(c, v, worksheet, rowNum, t);
		});
	}
};
