import React from "react";
import { ReactComponent as DroughtSvg } from "../../assets/perils/climate/drought_score.svg";
import { ReactComponent as WindSvg } from "../../assets/perils/climate/wind.svg";
import { ReactComponent as Flood1daySvg } from "../../assets/perils/climate/fluvial_flood_risk_change_1day_extreme.svg";
import { ReactComponent as Flood3daySvg } from "../../assets/perils/climate/fluvial_flood_risk_change_3day_extreme.svg";
import { ReactComponent as HeatWaveSvg } from "../../assets/perils/climate/heat_wave_score.svg";
import { ReactComponent as PrecipWinterSvg } from "../../assets/perils/climate/precipitation_change.svg";
import { ReactComponent as PrecipSummerSvg } from "../../assets/perils/climate/precipitation_change_jun_jul_aug.svg";
import { ReactComponent as SealevelRiseSvg } from "../../assets/perils/climate/sea_level_rise.svg";
import { ReactComponent as BushSvg } from "../../assets/perils/hazard/distance_to_bush.svg";
import { ReactComponent as EarthQuakeBedrockSvg } from "../../assets/perils/hazard/earthquake_bedrock_conditions.svg";
import { ReactComponent as EarthQkSoilSvg } from "../../assets/perils/hazard/earthquake_soil_conditions.svg";
import { ReactComponent as FloodSvg } from "../../assets/perils/hazard/fluvial_flood.svg";
import { ReactComponent as FloodSRSvg } from "../../assets/perils/hazard/fluvial_flood_sr.svg";
import { ReactComponent as SubsidenceSvg } from "../../assets/perils/hazard/france_subsidence.svg";
import { ReactComponent as HailstormSvg } from "../../assets/perils/hazard/hailstorm.svg";
import { ReactComponent as LandslideSvg } from "../../assets/perils/hazard/landslide.svg";
import { ReactComponent as LightningSvg } from "../../assets/perils/hazard/lightning.svg";
import { ReactComponent as PluvialFloodSvg } from "../../assets/perils/hazard/pluvial_flood_sr.svg";
import { ReactComponent as StormSvg } from "../../assets/perils/hazard/storm_surge.svg";
import { ReactComponent as TornadoSvg } from "../../assets/perils/hazard/tornado.svg";
import { ReactComponent as TsunamiSvg } from "../../assets/perils/hazard/tsunami.svg";
import { ReactComponent as VolcanoSvg } from "../../assets/perils/hazard/volcano_ash_thickness.svg";
import { ReactComponent as WildfireSvg } from "../../assets/perils/hazard/wildfire.svg";
import { ReactComponent as WindstormSvg } from "../../assets/perils/hazard/windstorm.svg";
import style from "./svg.module.scss";
const iconDict: { [key: string]: React.FunctionComponent<any> } = {
	FL_Fluvial_Official: FloodSvg,
	FL_Fluvial_SwissRe: FloodSvg,
	FL_Pluvial_SwissRe: PluvialFloodSvg,
	FL_Surge_SwissRe: StormSvg,
	EQ_Bedrock_Global_SwissRe: EarthQuakeBedrockSvg,
	EQ_Tsunami_SwissRe: TsunamiSvg,
	EQ_LocalSoilCondition_Global_SwissRe: EarthQkSoilSvg,
	WS_Windspeed_Global_SwissRe: WindstormSvg,
	CS_Hail_Global_SwissRe: HailstormSvg,
	CS_Tornado_Global_SwissRe: TornadoSvg,
	EQ_Landslide_Global_SwissRe: LandslideSvg,
	CS_Lightning_Global_SwissRe: LightningSvg,
	VO_AshThickness_Global_SwissRe: LightningSvg,
	WF_Wildfire_Global_SwissRe: WildfireSvg,
	WF_DistToBush_AUS_SwissRe: BushSvg,
	DR_Subsidence_France_SwissRe: SubsidenceSvg,
	GEO_DistToCoast_Global_SwissRe: BushSvg,
	drought: DroughtSvg,
	wind: WindSvg,
	wet: Flood1daySvg,
	boreal_winter_wet: PrecipWinterSvg,
	boreal_summer_wet: PrecipSummerSvg,
	heat_wave: HeatWaveSvg,
	sea_level_rise: SealevelRiseSvg,
	FloodSRSvg: FloodSRSvg,
	VolcanoSvg: VolcanoSvg,
};
interface Props {
	icon: string;
}
export default function Svg(props: Props) {
	const Icon = iconDict[props.icon];
	return () => <Icon className={style.icon} />;
}
