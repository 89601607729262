import ExcelJS, { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { getLanguage } from "helpers/translations";
import { excelSheetLocation } from "./sheet/excel-sheet-location";
import { excelSheetHazard } from "./sheet/excel-sheet-hazard";
import { excelSheetClimate } from "./sheet/excel-sheet-climate";

export const downloadExcel = async (context: any, t: any) => {
	const language = getLanguage();
	const templateUrl = language === "en" ? "/template_en.xlsx" : "/template_cn.xlsx";

	const response = await fetch(templateUrl);
	const templateBlob = await response.blob();
	const templateArray = await new Promise<Uint8Array>((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(new Uint8Array(reader.result as ArrayBuffer));
		reader.onerror = reject;
		reader.readAsArrayBuffer(templateBlob);
	});

	const workbook: Workbook = new ExcelJS.Workbook();

	await workbook.xlsx.load(templateArray);

	excelSheetLocation(context, t, workbook);
	excelSheetHazard(context, t, workbook);
	excelSheetClimate(context, t, workbook, 3, "SSP1-2.6");
	excelSheetClimate(context, t, workbook, 4, "SSP2-4.5");
	excelSheetClimate(context, t, workbook, 5, "SSP5-8.5");

	const buf = await workbook.xlsx.writeBuffer();

	saveAs(new Blob([buf], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), `${context.currentMultipleLocations?.name}.xlsx`);
};
