import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import LoginRecord from "./login-record/login-record";
import styles from "./login-report.module.scss";
import LoginStatistics from "./login-statistics/login-statistics";
import { useTranslation } from "react-i18next";

function LoginReport() {
	const { t } = useTranslation();
	const [tab, setTab] = useState(0);

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	return (
		<div className={styles.container}>
			<div className={styles.title}>{t("userSystem.loginReport")}</div>
			<Tabs
				value={tab}
				onChange={handleChangeTab}
			>
				<Tab label={t("userSystem.loginRecord")} />
				<Tab label={t("userSystem.loginStatistics")} />
			</Tabs>
			{tab === 0 && <LoginRecord />}
			{tab === 1 && <LoginStatistics />}
		</div>
	);
}

export default LoginReport;
