import React, { useState } from "react";
import { Button, Menu, MenuItem, Checkbox, ListItemText, ListItemIcon, Typography, List, ListItem } from "@mui/material";
import { statusBgColor } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import styles from "./status-dropdown.module.scss";

interface Props {
	selectedStatus: string[];
	onSave: (roles: string[]) => void;
}

const options = [
	{ label: "active", value: "1", color: "green" },
	{ label: "overdue", value: "2", color: "red" },
	{ label: "closed", value: "3", color: "orange" },
	{ label: "notActivated", value: "4", color: "gray" },
];

const StatusDropdown = (props: Props) => {
	const [t] = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState<string[]>(JSON.parse(JSON.stringify(props.selectedStatus)));


	const handleClick = (event: any) => {
		setSelectedStatus(JSON.parse(JSON.stringify(props.selectedStatus)));
		setAnchorEl(event.currentTarget);
	};

	const handleSave = () => {
		props.onSave(selectedStatus);
		handleClose()
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleToggle = (value: string) => () => {
		const currentIndex = selectedStatus.indexOf(value);
		const newChecked = [...selectedStatus];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedStatus(newChecked);
	};

	const open = Boolean(anchorEl);

	return (
		<div style={{ marginRight: "16px" }}>
			<Button
				color="success"
				variant="text"
				onClick={handleClick}
				endIcon={open ? <span className="material-icons">keyboard_arrow_down</span> : <span className="material-icons">keyboard_arrow_up</span>}
				style={{ color: "#333", textTransform: "none" }}
			>
				{t("userSystem.status")}
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<div className="p-2">
					<List>
						{options.map((option) => (
							<ListItem
								key={option.label}
								dense
								button
								onClick={handleToggle(option.value)}
							>
								<Checkbox
									size="small"
									edge="start"
									color="success"
									checked={selectedStatus.indexOf(option.value) !== -1}
									disableRipple
								/>
								<span
									className={styles.status}
									style={{ backgroundColor: statusBgColor(option.value) }}
								></span>
								<ListItemText>
									<Typography>{t("userSystem." + option.label)}</Typography>
								</ListItemText>
							</ListItem>
						))}
					</List>
					<div style={{ marginTop: "8px", display: "flex", justifyContent: "space-between" }}>
						<Button
							color="success"
							onClick={() => setSelectedStatus([])}
						>
							{t("single.buttons.reset")}
						</Button>
						<div>
							<Button
								color="success"
								onClick={handleClose}
							>
								{t("single.buttons.close")}
							</Button>
							<Button
								onClick={handleSave}
								color="success"
								variant="contained"
							>
								{t("locations.add.save")}
							</Button>
						</div>
					</div>
				</div>
			</Menu>
		</div>
	);
};

export default StatusDropdown;
