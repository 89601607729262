import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Icon from "components/icon/icon";
import { LeftPanel } from "helpers/constants";
import { AppContext, setLeftPanel } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AnalysisReport from "./addressAnalysisReport/analysisReport";
import LoginReport from "./login-report/login-report";
import Role from "./role/role";
import styles from "./system.module.scss";
import Tenant from "./tenant/tenant";
import UnconfirmedReport from "./unconfirmedReport/unconfirmedReport";
import User from "./user/user";

const menulist = [
	{
		title: "租户管理",
		id: "tenant",
	},
	{
		title: "用户管理",
		id: "user",
	},
	{
		title: "角色管理",
		id: "role",
	},
	{
		title: "报表",
		id: "report",
		children: [
			{
				title: "登录报表",
				id: "loginReport",
			},
			{
				title: "地址分析报表",
				id: "addressAnalysisReport",
			},
			// {
			// 	title: "待确认报表",
			// 	id: "unconfirmedReport",
			// },
		],
	},
];

const renderComponent: { [key: string]: React.FunctionComponent<any> } = {
	tenant: Tenant,
	user: User,
	role: Role,
	loginReport: LoginReport,
	unconfirmedReport: UnconfirmedReport,
	addressAnalysisReport: AnalysisReport,
};

function System() {
	const [context, dispatch] = useContext(AppContext);
	const [expanded, setExpanded] = useState(false);
	const [activeMenu, setActiveMenu] = useState("tenant");
	const handleChangeExpand = () => {
		setExpanded(expanded ? false : true);
	};
	const [t] = useTranslation();
	const CurrentComponent = renderComponent[activeMenu];

	return (
		<div className={styles.system}>
			<div className={styles.title}>
				<div>{t("userSystem.title")}</div>
				<div>
					<Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
				</div>
			</div>
			<div className={styles.content}>
				<div className={styles.leftContent}>
					<div className="text-[16px] font-medium">{t("userSystem.management")}</div>
					<div className="mt-[10px]">
						{menulist.map((menu, index) =>
							menu.children ? (
								<Accordion
									key={index}
									expanded={expanded}
									onChange={handleChangeExpand}
									sx={{
										boxShadow: "none",
										backgroundColor: "transparent",
										margin: "0!important",
										"&::before": {
											display: "none",
										},
									}}
								>
									<AccordionSummary
										expandIcon={
											<div
												className="material-icons"
												style={{ color: activeMenu === "loginReport" || activeMenu === "unconfirmedReport" ? "#007934" : "#666" }}
											>
												keyboard_arrow_right
											</div>
										}
										sx={{
											padding: "0 18px",
											color: activeMenu === "loginReport" || activeMenu === "unconfirmedReport" ? "#007934" : "#666",
											fontSize: "14px",
											fontWeight: 500,
											margin: 0,
											minHeight: "40px",
											"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
												transform: "rotate(90deg)",
											},
											"& .MuiTypography-root": {
												fontFamily: "SwissReSansOT-Light",
											},
											"&.Mui-expanded": {
												minHeight: "40px",
											},
											"& .MuiAccordionSummary-content": {
												margin: "8px 0",
											},
											"& .MuiAccordionSummary-content.Mui-expanded": {
												margin: "8px 0",
											},
										}}
									>
										{t("userSystem." + menu.id)}
									</AccordionSummary>
									<AccordionDetails
										sx={{
											padding: "6px 0 0",
											width: "100%",
										}}
									>
										<div className="bg-[#F8F8F8] p-[10px]">
											{menu.children.map((subMenu, subIndex) => (
												<div
													className={`${styles.menuItem} ${activeMenu === subMenu.id ? styles.active : ""}`}
													onClick={() => {
														setActiveMenu(subMenu.id);
													}}
													key={subIndex}
												>
													{t("userSystem." + subMenu.id)}
												</div>
											))}
										</div>
									</AccordionDetails>
								</Accordion>
							) : (
								<div
									className={`${styles.menuItem} ${activeMenu === menu.id ? styles.active : ""}`}
									onClick={() => {
										setActiveMenu(menu.id);
									}}
									key={index}
								>
									{t("userSystem." + menu.id)}
								</div>
							)
						)}
					</div>
				</div>
				<div className={styles.rightContent}>
					<CurrentComponent />
				</div>
			</div>
		</div>
	);
}

export default System;
