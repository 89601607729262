import React from "react";
import styles from "./CrsLegend.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";

const fontColor: string[] = ["#000000", "#275317", "#4ea72e", "#9c0006", "#9c0006", "#ffffff"];

const backgroundColor: string[] = ["#F2F2F2", "#b5e6a2", "#daf2d0", "#ffffcc", "#ffc7ce", "#cc0000"];

export default function CrsLegend() {
	const [t] = useTranslation();

	const rows: any[] = t("crsLegend.data", { returnObjects: true });

	return (
		<TableContainer
			component={Paper}
			sx={{ width: 780, overflow: "auto", boxShadow: "none" }}
		>
			<Table
				sx={{ border: "1px solid #d3d3d3", backgroundColor: "#F2F2F2" }}
				size="small"
				aria-label="simple table"
			>
				<TableBody>
					{rows.map((row: any, i: number) => (
						<TableRow key={i}>
							{row.map((cell: string, j: number) => (
								<TableCell
									key={j}
									align={`${j === 0 ? "left" : "center"}`}
									sx={{ border: "1px solid #d3d3d3", fontSize: "14px", whiteSpace: "nowrap", width: "16.6%", backgroundColor: backgroundColor[j], color: fontColor[j] }}
								>
									{cell}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
