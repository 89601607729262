import { Worksheet } from "exceljs";

export const tx = (col: string, value: any, worksheet: Worksheet, row: number, t: any) => {
	// console.log(col, value, worksheet, row);

	if (!col) {
		return;
	}

	const cell = worksheet.getCell(`${col}${row}`);

	if (value === "0" || value === 0) {
		cell.value = 1;
		return;
	}

	if (typeof value === "number" || t === null) {
		cell.value = value;
		return;
	}

	if (!isNaN(value)) {
		const valueInt = Math.round(value * 100) / 100;
		cell.value = valueInt;
		return;
	}

	const valueFy = t("legend." + value);
	if (!valueFy.startsWith("legend.")) {
		cell.value = valueFy;
		return;
	}

	if (value === "NaN" || value === "undefined" || value === "null") {
		const value = t("report.NaN");
		cell.value = value;
		return;
	}

	cell.value = value;
};
