import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import styles from "./analysisReport.module.scss";
import AnalysisRecord from "./analysis-record/analysisRecord";
import AnalysisStatistics from "./analysis-statistics/analysisStatistics";
import { useTranslation } from "react-i18next";

function AnalysisReport() {
	const { t } = useTranslation();
	const [tab, setTab] = useState(0);

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	return (
		<div className={styles.container}>
			<div className={styles.title}>{t("userSystem.addressAnalysisReport")}</div>
			<Tabs
				value={tab}
				onChange={handleChangeTab}
			>
				<Tab label={t("userSystem.analysisRecord")} />
				<Tab label={t("userSystem.analysisStatistics")} />
			</Tabs>
			{tab === 0 && <AnalysisRecord />}
			{tab === 1 && <AnalysisStatistics />}
		</div>
	);
}

export default AnalysisReport;
