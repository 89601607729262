import { useState, useEffect, useContext } from "react";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableSortLabel,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Tabs,
    Tab,
    Toolbar,
    IconButton,
    TextField,
    Box,
    InputAdornment,
    Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { getAnalysisRecord, downloadAnalysisRecord } from "api/layer";
import { AppContext, AppAction } from "helpers/context";
import styles from "./analysisRecord.module.scss";
import StartTimeDropdown from "./start-time-dropdown/start-time-dropdown";
import EndTimeDropdown from "./end-time-dropdown/end-time-dropdown";
import { statusBgColor, statusText, statusText1 } from "helpers/helpers";
import dayjs from "dayjs";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

function AnalysisRecord() {
    const { t } = useTranslation();
    const [context, dispatch] = useContext(AppContext);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [analysisRecordData, setAnalysisRecordData] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [searchIpt, setSearchIpt] = useState("");
    const [searchData, setSearchData] = useState<{
        keyword: string;
        startOccurTime: any;
        endOccurTime: any;
    }>({
        keyword: "",
        startOccurTime: null,
        endOccurTime: null,
    });

    const [sortByField, setSortByField] = useState<"user_account" | "event_type" | "perils" | "location_amount" | "coordinates" | "occur_time" | "status">("occur_time");
    const [sortOrder, setSortOrder] = useState<{
        user_account: "asc" | "desc",
        event_type: "asc" | "desc",
        perils: "asc" | "desc",
        location_amount: "asc" | "desc",
        coordinates: "asc" | "desc",
        occur_time: "asc" | "desc",
        status: "asc" | "desc",
    }>({
        user_account: "asc",
        event_type: "asc",
        perils: "asc",
        location_amount: "asc",
        coordinates: "asc",
        occur_time: "desc",
        status: "asc",
    });



    const hanldeDownload = () => {
        downloadAnalysisRecord({ ...searchData, language: context.language }).then((res: any) => {
            console.log(res);
            const url = window.URL.createObjectURL(new Blob([res as any], { type: "application/force-download;charset=utf-8" }));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${t("userSystem.analysisRecordDownload")}.xls`);
            document.body.appendChild(link);
            link.click();
            link.onload = () => {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            };
        });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleSearch = (params: any) => {
        setSearchData((prevData) => ({ ...prevData, [params.name]: params.value }));
        if ((params.name === "startOccurTime" || params.name === "endOccurTime") && params.value && params.value.format("YYYY-MM-DD") === "Invalid Date") {
            return;
        }
        setPage(0);
        handleGetAnalysisRecordData({ pageNo: 1, search: { ...searchData, [params.name]: params.value } });
    };

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            handleSearch({ name: "keyword", value: event.target.value });
        }
    };

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
        handleGetAnalysisRecordData({ pageNo: newPage + 1 });
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        handleGetAnalysisRecordData({ pageNo: 0, pageSize: parseInt(event.target.value, 10) });
    };

    const handleGetAnalysisRecordData = async ({
        pageNo = page + 1,
        pageSize = rowsPerPage,
        sortOrderAsc = sortOrder[sortByField] === "asc",
        sortByFields = sortByField,
        search = searchData } = {}) => {
        setIsLoading(true);
        let params = { ...search };
        if (search.startOccurTime) {
            params.startOccurTime = search.startOccurTime.format("YYYY-MM-DD");
        }
        if (search.endOccurTime) {
            params.endOccurTime = search.endOccurTime.format("YYYY-MM-DD");
        }
        const res: any = await getAnalysisRecord({ ...params }, { pageNo, pageSize, sortOrderAsc, sortByField: sortByFields, });
        setIsLoading(false);
        if (res.code !== 200) return;
        setAnalysisRecordData(res.data.rows);
        setTotalCount(res.data.totalCount * 1 || 0);
    };

    useEffect(() => {
        handleGetAnalysisRecordData();
    }, []);

    return (
        <div className={styles.container}>
            <Toolbar style={{ justifyContent: "space-between", borderBottom: "1px solid #ccc", marginBottom: "5px", padding: "0" }}>
                <div>
                    <Button
                        size="small"
                        variant="outlined"
                        color="success"
                        style={{ padding: "4px 10px", marginRight: "10px" }}
                        onClick={hanldeDownload}
                    >
                        {t("single.buttons.run")}
                    </Button>
                </div>

                <IconButton
                    color="success"
                    onClick={toggleFilters}
                    style={{ color: showFilters ? "#2e7d32" : "rgba(0, 0, 0, 0.54)" }}
                >
                    <span className="material-icons">filter_list</span>
                </IconButton>
            </Toolbar>

            {showFilters && (
                <Box style={{ display: "flex", alignItems: "center", padding: "8px", backgroundColor: "#f5f5f5", justifyContent: "space-between" }}>
                    <Box style={{ display: "flex", flex: 1, alignItems: "center" }}>
                        <TextField
                            color="success"
                            variant="outlined"
                            size="small"
                            placeholder={t("userSystem.filterByKeyword")}
                            value={searchIpt}
                            onChange={(e) => setSearchIpt(e.target.value)}
                            onKeyDown={handleKeyDown}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <span className="material-icons">filter_list</span>
                                    </InputAdornment>
                                ),
                                endAdornment: searchIpt && (
                                    <InputAdornment position="end">
                                        <span
                                            className="material-icons cursor-pointer"
                                            onClick={() => {
                                                setSearchIpt("");
                                                searchData.keyword && handleSearch({ name: "keyword", value: "" });
                                            }}
                                        >
                                            close
                                        </span>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                width: "250px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "0",
                                },
                                "& .MuiOutlinedInput-input,& .MuiOutlinedInput-input::placeholder": {
                                    fontSize: "14px",
                                },
                            }}
                        />
                        <StartTimeDropdown
                            defaultTime={searchData.startOccurTime}
                            onSave={(value) => handleSearch({ name: "startOccurTime", value })}
                        />
                        <EndTimeDropdown
                            defaultTime={searchData.endOccurTime}
                            onSave={(value) => handleSearch({ name: "endOccurTime", value })}
                        />
                    </Box>
                    <IconButton
                        onClick={() => setShowFilters(false)}
                        aria-label="clear"
                        color="default"
                    >
                        <span className="material-icons">clear</span>
                    </IconButton>
                </Box>
            )}

            <div style={{ flex: 1, overflow: "auto" }}>
                <Table
                    stickyHeader
                    aria-label="sticky  table"
                    size="small"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ minWidth: 70 }}>{t("userSystem.index")}</TableCell>

                            <TableCell style={{ minWidth: 150 }}>
                                <TableSortLabel
                                    active={sortByField === "user_account"}
                                    onClick={() => {
                                        handleGetAnalysisRecordData({ sortOrderAsc: !(sortOrder.user_account === "asc"), sortByFields: "user_account" })
                                        setSortByField("user_account")
                                        let sort: any = {
                                            ...sortOrder,
                                            user_account: sortOrder.user_account === "asc" ? "desc" : "asc"
                                        }
                                        setSortOrder(sort)
                                    }}
                                    direction={sortOrder.user_account}
                                >

                                    {t("userSystem.userAccount")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ minWidth: 140 }}>
                                <TableSortLabel
                                    active={sortByField === "event_type"}
                                    onClick={() => {
                                        handleGetAnalysisRecordData({ sortOrderAsc: !(sortOrder.event_type === "asc"), sortByFields: "event_type" })
                                        setSortByField("event_type")
                                        let sort: any = {
                                            ...sortOrder,
                                            event_type: sortOrder.event_type === "asc" ? "desc" : "asc"
                                        }
                                        setSortOrder(sort)
                                    }}
                                    direction={sortOrder.event_type}
                                >

                                    {t("userSystem.eventType")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ minWidth: 100 }}>
                                <TableSortLabel
                                    active={sortByField === "perils"}
                                    onClick={() => {
                                        handleGetAnalysisRecordData({ sortOrderAsc: !(sortOrder.perils === "asc"), sortByFields: "perils" })
                                        setSortByField("perils")
                                        let sort: any = {
                                            ...sortOrder,
                                            perils: sortOrder.perils === "asc" ? "desc" : "asc"
                                        }
                                        setSortOrder(sort)
                                    }}
                                    direction={sortOrder.perils}
                                >
                                    {t("userSystem.perils")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ minWidth: 100 }}>
                                <TableSortLabel
                                    active={sortByField === "location_amount"}
                                    onClick={() => {
                                        handleGetAnalysisRecordData({ sortOrderAsc: !(sortOrder.location_amount === "asc"), sortByFields: "location_amount" })
                                        setSortByField("location_amount")
                                        let sort: any = {
                                            ...sortOrder,
                                            location_amount: sortOrder.location_amount === "asc" ? "desc" : "asc"
                                        }
                                        setSortOrder(sort)
                                    }}
                                    direction={sortOrder.location_amount}
                                >

                                    {t("userSystem.amount")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell >
                                <TableSortLabel
                                    active={sortByField === "coordinates"}
                                    onClick={() => {
                                        handleGetAnalysisRecordData({ sortOrderAsc: !(sortOrder.coordinates === "asc"), sortByFields: "coordinates" })
                                        setSortByField("coordinates")
                                        let sort: any = {
                                            ...sortOrder,
                                            coordinates: sortOrder.coordinates === "asc" ? "desc" : "asc"
                                        }
                                        setSortOrder(sort)
                                    }}
                                    direction={sortOrder.coordinates}
                                >

                                    {t("userSystem.coordinates")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ minWidth: 120 }}>
                                <TableSortLabel
                                    active={sortByField === "occur_time"}
                                    onClick={() => {
                                        handleGetAnalysisRecordData({ sortOrderAsc: !(sortOrder.occur_time === "asc"), sortByFields: "occur_time" })
                                        setSortByField("occur_time")
                                        let sort: any = {
                                            ...sortOrder,
                                            occur_time: sortOrder.occur_time === "asc" ? "desc" : "asc"
                                        }
                                        setSortOrder(sort)
                                    }}
                                    direction={sortOrder.occur_time}
                                >
                                    {t("userSystem.dateTime")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ minWidth: 100 }}>
                                <TableSortLabel
                                    active={sortByField === "status"}
                                    onClick={() => {
                                        handleGetAnalysisRecordData({ sortOrderAsc: !(sortOrder.status === "asc"), sortByFields: "status" })
                                        setSortByField("status")
                                        let sort: any = {
                                            ...sortOrder,
                                            status: sortOrder.status === "asc" ? "desc" : "asc"
                                        }
                                        setSortOrder(sort)
                                    }}
                                    direction={sortOrder.status}
                                >
                                    {t("userSystem.Status")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={10}>
                                    <div className={styles.loading}>
                                        <div className="loading loading-spinner loading-lg"></div>
                                        <div>{t("layers.loading")}</div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ) : (
                            analysisRecordData.map((item: any, index: number) => (
                                <TableRow
                                    key={item.id}
                                    className={styles.row}
                                >
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{item.userAccount}</TableCell>
                                    <TableCell>{item.eventType}</TableCell>
                                    <TableCell>{item.perils}</TableCell>
                                    <TableCell>{item.locationAmount}</TableCell>
                                    <TableCell style={{ wordBreak: "break-word" }}>{item.coordinates}</TableCell>
                                    <TableCell>{item.occurTime}</TableCell>
                                    <TableCell>
                                        {item.status ? t("userSystem." + statusText1(item.status)) : item.status}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("userSystem.rowsPerPage")}
            />
        </div >
    );
}

export default AnalysisRecord;
