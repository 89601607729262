import styles from "./snackBar.module.scss";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Icon from "components/icon/icon";
import { AppContext, AppAction, } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function SnackBar() {
    const [context, dispatch] = useContext(AppContext);
    const [t] = useTranslation();

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch({ type: AppAction.setSnackBarParams, payload: undefined });
    };

    return (<>{
        context.snackBarParams &&
        <Snackbar
            open={context.snackBarParams !== undefined}
            autoHideDuration={5000}
            onClose={handleClose}
            message={
                (<div className="flex flex-row items-end min-w-[290px]">
                    <div className="mr-[10px] flex-1">
                        <div
                            className="flex flex-row items-center"
                            style={{ marginBottom: (context.snackBarParams.message || (context.snackBarParams.message1 && context.snackBarParams.message2)) && '10px' }}>
                            <div
                                className="material-icons"
                                style={{
                                    color: context.snackBarParams!.titleIconColor || 'green',
                                    width: '20px',
                                    height: '20px',
                                    fontSize: '20px',
                                    marginRight: '10px'
                                }}
                            >{context.snackBarParams!.titleIcon || 'check_circle'}</div>
                            {context.snackBarParams && (context.snackBarParams.title || (context.snackBarParams.title1 && context.snackBarParams.title2)) &&
                                (<span className="text-[16px]">{
                                    context.snackBarParams.title ?
                                        t(`multiple.${context.snackBarParams.title}`) :
                                        t(`multiple.${context.snackBarParams.title1}`) + context.snackBarParams!.text + t(`multiple.${context.snackBarParams.title2}`)}</span>)}
                        </div>
                        {context.snackBarParams && (context.snackBarParams.message || context.snackBarParams.resMessage || (context.snackBarParams.message1 && context.snackBarParams.message2)) &&
                            (<div className="text-[14px] text-[#666] mb-[20px] ml-[30px] max-w-[300px]">{
                                context.snackBarParams.resMessage ? context.snackBarParams.resMessage :
                                    (context.snackBarParams.message ?
                                        t(`multiple.${context.snackBarParams.message}`)
                                        :
                                        t(`multiple.${context.snackBarParams.message1}`) + context.snackBarParams!.text + t(`multiple.${context.snackBarParams.message2}`))
                            }</div>)}
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{ color: '#666', width: '20px', height: '20px', fontSize: '20px', marginRight: '10px' }}
                        onClick={handleClose}
                    >
                        <Icon />
                    </IconButton>
                </div>
                )
            }
        />
    }</>);
}

export default SnackBar;
